<template>
  <!-- BEGIN SITE HEADER -->
  <header id="header" class="site-header is-transparent">
    <div class="container-fluid">
      <div class="row">
        <!-- removed flex ( flex-align-c inner )-->
        <div class="col-lg-3 col-6">
          <div class="header-left flex flex-align-c">
            <div class="logo">
              <router-link :to="{ name: 'BonoHome' }">
                <img
                  src="@/assets/img/mealbono.png"
                  alt="MealBono"
                  class="img-fluid"
                />
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-0 text-center">
          <div class="header-center" v-if="showNav == 'main'">
            <div class="main-menu">
              <div class="menu-action">
                <span class="item menu-back"
                  ><i class="las la-arrow-left"></i
                ></span>
                <span class="item menu-close"
                  ><i class="las la-times"></i
                ></span>
              </div>
              <ul v-if="sortedOrders">
                <li class="is-mega-menu">
                  <router-link
                    :class="{ 'text-primary': showNavActive == 'home' }"
                    :to="{ name: 'BonoHome' }"
                    >Home</router-link
                  >
                </li>
                <li class="is-mega-menu">
                  <router-link
                    :class="{ 'text-primary': showNavActive == 'serve' }"
                    :to="{ name: 'BonoServe' }"
                    >Create Token</router-link
                  >
                </li>
                <li class="is-mega-menu">
                  <router-link
                    :class="{ 'text-primary': showNavActive == 'history' }"
                    :to="{ name: 'BonoHistory' }"
                    >Vouchers</router-link
                  >
                </li>
                <li class="is-mega-menu">
                  <router-link
                    :class="{
                      'text-danger':
                        sortedOrders.length > 0 && showNavActive != 'orders',
                      'text-primary': showNavActive == 'orders',
                      'text-dark': showNavActive == 'voucher',
                    }"
                    :to="{ name: 'BonoOrders' }"
                    >Orders
                    {{
                      sortedOrders.length > 0 ? `(${sortedOrders.length})` : ""
                    }}</router-link
                  >
                </li>
                <li class="is-mega-menu">
                  <router-link
                    :class="{ 'text-primary': showNavActive == 'accounts' }"
                    :to="{ name: 'BonoAccounts' }"
                    >Accounts</router-link
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-6">
          <div class="header-right flex flex-align-c flex-content-e">
            <template v-if="showNav == 'main'">
              <div class="customer">
                <router-link :to="{ name: 'BonoHome' }">
                  <span class="text-primary">{{ userName }}</span>
                </router-link>
              </div>
              <div class="buttons">
                <router-link
                  class="button fullfield-dark d-none d-lg-block"
                  :to="{ name: 'BonoLogin' }"
                  ><i class="las la-sign-out-alt" title="Logout"></i
                  ><span>Logout</span></router-link
                >
                <span
                  class="button fullfield-dark my-cursor-clicker d-lg-none"
                  @click="isOpen = true"
                  ><i class="las la-bars la-2x" title="Menu"></i
                ></span>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>

    <!-- MOBILE -->
    <Slide
      :isOpen="isOpen"
      @closeMenu="isOpen = false"
      :closeOnNavigation="true"
      :burgerIcon="false"
      :width="viewportWidth"
      noOverlay
      right
    >

    <router-link :to="{ name: 'BonoHome' }" v-if="userName">
        <span class="text-secondary h3">{{ userName }}</span>
      </router-link>

      
      <router-link :to="{ name: 'BonoHome' }">
        <span class="text-info h1"
          ><img
            src="@/assets/img/mealbono-menu.png"
            alt="MealBono"
            class="img-fluid"
        /></span>
      </router-link>


      <router-link :to="{ name: 'BonoHome' }"
        ><span :class="{ 'text-info': showNavActive == 'home' }"
          >Home</span
        ></router-link
      >
      <hr width="75%" />

      <router-link :to="{ name: 'BonoServe' }"
        ><span :class="{ 'text-info': showNavActive == 'serve' }"
          >Create Token</span
        ></router-link
      >

      <hr width="75%" />

      <router-link :to="{ name: 'BonoHistory' }"
        ><span :class="{ 'text-info': showNavActive == 'history' }"
          >Vouchers</span
        ></router-link
      >

      <hr width="75%" />

      <router-link :to="{ name: 'BonoOrders' }"
        ><span
          v-if="sortedOrders"
          :class="{
            'text-warning':
              sortedOrders.length > 0 && showNavActive != 'orders',
            'text-info': showNavActive == 'orders',
            'text-white': showNavActive == 'voucher',
          }"
          >Orders
          {{ sortedOrders.length > 0 ? `(${sortedOrders.length})` : "" }}</span
        ></router-link
      >

      <hr width="75%" />

      <router-link :to="{ name: 'BonoAccounts' }"
        ><span :class="{ 'text-info': showNavActive == 'accounts' }"
          >Accounts</span
        ></router-link
      >

      <hr width="75%" />

      <router-link :to="{ name: 'BonoLogin' }"
        ><span class="button fullfield">Logout</span></router-link
      >
      <p><br /><br /></p>
    </Slide>
  </header>
  <!-- END SITE HEADER -->
</template>
  
  <script>
import { computed, ref, watchEffect, onMounted, onBeforeUnmount } from "vue";
import { Slide } from "vue3-burger-menu";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

// Watcher
import watchOrderBox from "@/composables/firebaseDatabase/watchOrderBox";

export default {
  components: { Slide },
  props: ["userName", "userEmail"],
  setup(props) {
    const userEmail = ref("");
    const userName = ref("");
    const isOpen = ref(false);

    watchEffect(() => {
      userName.value = props.userName;
      userEmail.value = props.userEmail;
    });

    // route
    const route = useRoute();

    // router
    const router = useRouter();

    // store
    const store = useStore();

    // Create a ref to store the viewport width
    const viewportWidth = ref(`${window.innerWidth}`);

    // Function to update the viewport width
    const updateViewportWidth = () => {
      viewportWidth.value = `${window.innerWidth}`;
    };

    // Add event listener on component mount
    onMounted(() => {
      window.addEventListener("resize", updateViewportWidth);
    });

    // Remove event listener on component unmount
    onBeforeUnmount(() => {
      window.removeEventListener("resize", updateViewportWidth);
    });

    // watchOrder
    const { res: liveOrderBox, err: errOrder } = watchOrderBox(
      store.state.user.uid
    );

    // sortedOrders
    const sortedOrders = computed(() => {
      if (liveOrderBox.value) {
        return [...liveOrderBox.value].sort((b, a) => {
          return a.createdAt.toMillis() - b.createdAt.toMillis();
        });
      }
    });

    // showNav
    const showNav = computed(() => {
      return "main";
    });

    // showNavActive
    const showNavActive = computed(() => {
      if (route.path.includes("/bono/serve/")) {
        return "serve";
      } else if (route.path.includes("/bono/history/")) {
        return "history";
      } else if (route.path.includes("/bono/orders/")) {
        return "orders";
      } else if (route.path.includes("/bono/voucher/")) {
        return "voucher";
      } else if (route.path.includes("/bono/accounts/")) {
        return "accounts";
      } else {
        return "home";
      }
    });

    const scrollSmooth = (id) => {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    };

    return {
      viewportWidth,
      sortedOrders,
      showNav,
      showNavActive,
      userEmail,
      userName,
      isOpen,
      scrollSmooth,
    };
  },
};
</script>
  
<style>
.my-cursor-clicker {
  cursor: pointer;
}
</style>