import { ref } from 'vue';

const getMenu = async () => {
  const res = ref(null);
  const error = ref(null);

  try {
    const response = await fetch('https://vanish.i.ng/api-mealbono/getters/get_menu.php');
    if (!response.ok) {
      throw new Error('Network response was not OK');
    }
    const data = await response.json();
    res.value = data;
  } catch (err) {
    error.value = err;
  }

  return { res, error };
};

export default getMenu;
