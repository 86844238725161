<template>
  <infinite-slide-bar :barStyle="{ background: '#42b883', padding: '5px 0' }">
      <span style="color: #fff;">Helo World, I love Vue Infinite Slide Bar</span>
  </infinite-slide-bar>
  <infinite-slide-bar duration="24s" :barStyle="{ background: '#42b883', padding: '0px 0' }">
    <img src="http://icons.iconarchive.com/icons/graphicloads/100-flat/256/home-icon.png">

      <img src="http://icons.iconarchive.com/icons/graphicloads/100-flat/256/home-icon.png">

      <img src="http://icons.iconarchive.com/icons/graphicloads/100-flat/256/home-icon.png">

      <img src="http://icons.iconarchive.com/icons/graphicloads/100-flat/256/home-icon.png">

      <img src="http://icons.iconarchive.com/icons/graphicloads/100-flat/256/home-icon.png">
  </infinite-slide-bar>
</template>

<script>
import InfiniteSlideBar from 'vue3-infinite-slide-bar';

export default {
  components: {
        'infinite-slide-bar': InfiniteSlideBar
    },
  setup() {
 
    return {
    
    };
  },
};
</script>

<style scoped>
.variable-width-slider .slither-slide {
  width: var(--slide-width, 200px);
}
</style>
