import { ref, watchEffect } from 'vue';
import { db } from '@/firebase/config';
import { collectionGroup, onSnapshot, where, query } from 'firebase/firestore';

const watchOrderBox = (ownerId) => {

    // Start
    const res = ref(null),
          err = ref(null);

    // hard-coded variables      
    const SUB_COLLECTIONS = 'orderBox';      
    const STATUS_FIELD = 'status';
    const STATUS_VALUE = 'r';
    const OWNER_FIELD = 'owner';
    const OWNER_VALUE = ownerId;

    try {
        // Firebase magic
        const subcollectionQuery = collectionGroup(db, SUB_COLLECTIONS);

        const unsub = onSnapshot(
            query(
                subcollectionQuery,
                where(STATUS_FIELD, '==', STATUS_VALUE),
                where(OWNER_FIELD, '==', OWNER_VALUE)
            ),
            (querySnapshot) => {
                const results = [];

                querySnapshot.forEach((doc) => {
                    results.push({ ...doc.data(), id: doc.id });
                });

                res.value = results;

            },
            (error) => {
                // Handle the error here
                err.value = error.message;
            }
        );

        watchEffect((onInvalidate) => {
            onInvalidate(() => unsub());
        });

    } catch (error) {
        if (error.code === 'permission-denied') {
            err.value = 'Permission denied';
        } else {
            err.value = 'Error getting orderBox';
        }
    }

    return { res, err };
};

export default watchOrderBox;
