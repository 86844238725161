// Transaction Start Order
import { doc, runTransaction, increment, collection } from 'firebase/firestore';
import { Timestamp } from "firebase/firestore";
import { db } from '@/firebase/config';
import { ref } from 'vue';

const transactionStartOrder = async (ownerId, voucherId, document, expiredAt) => {
    // Start
    const res = ref(null);
    const err = ref(null);

    // Hard-coded variables
    const COLLECTION_NAME = 'owner';
    const SUB_COLLECTION_NAME = 'voucher';
    const SUB_SUB_COLLECTION_NAME = 'orderBox';
    const INCREASE_PEOPLE_COUNT = 1;
    const DECREASE_PEOPLE_COUNT = -1;
    const OWNER_DOC = ownerId;
    const VOUCHER_DOC = voucherId;
    const DOCUMENT_DATA = document;

    // Firebase Variables
    const voucherRef = doc(db, COLLECTION_NAME, OWNER_DOC, SUB_COLLECTION_NAME, VOUCHER_DOC);
    const orderRef = collection(voucherRef, SUB_SUB_COLLECTION_NAME);
    const newOrder = doc(orderRef);
    const OrderDocumentRef = doc(orderRef, newOrder.id);

    try {

        if (!(Timestamp.now().toMillis() > expiredAt.toMillis())) {


            await runTransaction(db, async (transaction) => {

                // Get the current document data
                const documentSnapshot = await transaction.get(voucherRef);
                const voucherGetData = documentSnapshot.data();

                // Check if there is still space
                if (voucherGetData.peopleLeft > 0) {

                    // Update voucher record
                    transaction.update(voucherRef, { peopleLeft: increment(DECREASE_PEOPLE_COUNT), orderPending: increment(INCREASE_PEOPLE_COUNT) });

                    // Create a new order in the orderBox subcollection
                    transaction.set(OrderDocumentRef, { ...DOCUMENT_DATA, oid: newOrder.id });

                    // Output response
                    res.value = newOrder.id;

                } else {

                    err.value = "Voucher is completely used up!";

                }

            });


        } else {

            err.value = "Voucher has expired!";

        }


    } catch (error) {

        if (error.code === "permission-denied") {
            // Permission error
            err.value = "Permission denied";
        } else {
            // Handle other non-permission errors here
            //err.value = "Error starting Order, please try again";
            err.value = error.message;
        }

    }


    return { res, err }
};


export default transactionStartOrder;