<template>
  <WelcomeHome />
</template>


<script>
// main
import { onMounted } from "vue";
import { useRouter } from "vue-router";

// others
import WelcomeHome from "@/components/pages/Welcome.vue";

export default {
  name: "Home",
  components: { WelcomeHome },
  props: ["id"],

  setup(props) {

    // router
    const router = useRouter();

    //scroll to top
    const element = document.getElementById("topmost");
    if (element) {
    element.scrollIntoView({ behavior: "smooth", block: "start" });
    }

    onMounted(() => {
      if (props.id) {
        router.push({ name: "V", params: { id: props.id } });
      }
    });

   
  },

};
</script>

<style>
.text-orange {
  color: orange;
}

.item a:hover {
  color: red;
}

.custom-skyblue-bg {
  background-color: #eef2f7;
}

.custom-yellow-bg {
  background-color: #fcf3cf;
}
.drop {
  border: 2px solid black; /* Set the border style, width, and color */
  padding: 10px; /* Add some padding for spacing */
  color: yellow; /* Set the text color */
  display: inline;
}
</style>
