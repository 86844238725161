<template>
  <div class="site-content pt0 pb0 pt-5">
    <section class="section spdt mt-5">
      <div class="container">
        <div class="heading align-left">
          <div class="heading-sub color-navy">Admin Area</div>
          <div class="heading-sub">
            Order Cart<i class="las la-shopping-bag la-2x"></i>
          </div>
        </div>
      </div>
    </section>

    <span id="topOrder"></span>
    <template v-if="rOrders">
      <div class="site-content bg-secondary" v-if="rOrders.length">
        <div class="container">
          <div class="heading align-center">
            <div class="toggle">
              <h2 class="heading-title size-l text-white">
                Pending<i class="las la-cart-plus la-2x"></i>
              </h2>
              <span class="discount">
                <span class="discount-text text-warning">Action required</span>
              </span>
            </div>
          </div>

          <form @submit.prevent="handleOrder" class="cart-form">
            <div class="table-responsive">
              <table class="table cart-table">
                <thead>
                  <tr>
                    <th></th>
                    <th class="product-thumbnail">Meal</th>
                    <th>Name</th>
                    <th>Meal Desc</th>
                    <th>Vendor</th>
                    <th class="product-subtotal">Price</th>
                    <th class="product-subtotal">Delivery</th>
                    <th class="product-subtotal">Subtotal</th>
                  </tr>
                </thead>
                <tbody v-for="order in rOrders" :key="order.oid">
                  <tr :class="{ 'custom-yellow-bg': order.editOrder }">
                    <td>
                      <span v-if="order.editOrder">
                        <img
                          src="@/assets/img/spinner.gif"
                          width="48"
                          v-if="isDeleting"
                        />

                        <span
                          class="btn btn-primary"
                          title="Go Back"
                          @click="handleEdit('back')"
                          v-if="!isDeleting"
                          ><i class="las la-undo text-white"></i></span
                        >&nbsp;
                        <span
                          class="btn btn-danger"
                          title="Confirm Removal"
                          @click="handleEdit('confirm')"
                          v-if="!isDeleting"
                          ><i class="las la-times text-white"></i
                        ></span>
                      </span>

                      <span
                        class="my-cursor-clicker"
                        title="Remove"
                        @click="handleEdit(order.id)"
                        v-else
                      >
                        <i class="las la-times text-danger"></i>
                      </span>
                    </td>
                    <td class="product-thumbnail">
                      <img :src="order.mealUrl" :alt="order.mealOrder" />
                    </td>
                    <td>
                      <p class="text-dark">
                        {{ order.orderName }} ({{ order.orderPhone }}) <br />
                        {{ order.orderSpecial }}
                      </p>
                    </td>
                    <td>
                      <h5>{{ order.mealOrder }}</h5>
                      <p>{{ order.mealDesc }}</p>
                    </td>
                    <td>
                      <p>{{ order.vendor }}</p>
                    </td>
                    <td class="product-subtotal">
                      {{
                        `₦${order.mealPrice
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                      }}
                    </td>

                    <td class="product-subtotal">₦650</td>

                    <td class="product-subtotal">
                      <h5>
                        {{
                          `₦${(Number(order.mealPrice) + 650)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                        }}
                      </h5>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div class="field-group flex flex-align-c flex-content-sb">
                <div class="field-input"></div>
                <div class="field-input">
                  <input
                    type="submit"
                    :value="
                      isSubmiting
                        ? 'Processing Order...'
                        : `Authorize Order (₦${rOrdersTotal
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')})`
                    "
                    :disabled="isSubmiting"
                    name="update_cart"
                    v-if="rOrdersTotal"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </template>

    <span id="dashboard"></span>
    <section
      class="section background-dark spdtb"
      v-if="liveOwner && liveVoucher"
    >
      <div class="container">
        <div
          class="heading heading-alway-white align-center"
          :class="{ 'mb-4': openToken }"
        >
          <p class="text-secondary">
            Created
            {{
              new Date(liveVoucher.createdAt.toMillis()).toLocaleString(
                undefined,
                {
                  month: "short",
                  day: "numeric",
                  year: "numeric",
                }
              )
            }}
          </p>
          <h1 class="heading-title size-l">
            Meal Token <i class="las la-check-circle"></i>
          </h1>
          <p
            class="text-primary my-cursor-clicker"
            :class="{ 'text-info': openToken }"
            :title="openToken ? 'Close Token' : 'Open Token'"
            @click="openToken = !openToken"
          >
            {{ openToken ? "Hide Token" : "Show Token" }}
          </p>
        </div>

        <div class="swiper-wrapper custom-yellow-bg mb-4" v-if="openToken">
          <div class="swiper-slide">
            <div class="item">
              <div class="content mx-5 my-5">
                <h3 class="heading-title size-l text-center text-wrap">
                  {{ liveVoucher.message }}
                  <br />
                  <router-link
                    :to="{ name: 'Home', params: { id: liveVoucher.vid } }"
                    target="_blank"
                  >
                    <span class="text-primary">
                      <span class="d-none d-lg-block"
                        >https://mealbono.com/{{ liveVoucher.vid }}</span
                      >
                      <span class="d-lg-none">{{ liveVoucher.vid }}</span>
                    </span>
                  </router-link>

                  {{ liveVoucher.sender }}
                  <span @click="copyToClipboard"
                    ><i
                      class="las la-copy la-3x my-cursor-clicker"
                      :class="{
                        'text-success': isCopy,
                        'text-warning': !isCopy,
                      }"
                      title="Copy Token"
                    ></i
                  ></span>
                </h3>
              </div>
            </div>
          </div>
        </div>

        <div class="block-counter align-center layout-01">
          <div class="row">
            <div class="col-lg-3 col-6">
              <div class="counter-box color-white">
                <div class="sub">Total Budget (₦)</div>
                <div class="number">
                  <span class="counter">{{
                    `${(liveVoucher.amount * liveVoucher.peopleTotal)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                  }}</span>
                </div>
                <div class="title">
                  {{
                    `${liveVoucher.amount
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                  }}
                  per Meal
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-6">
              <div class="counter-box color-white">
                <div class="sub">People Total</div>
                <div class="number">
                  <span class="counter">{{ liveVoucher.peopleTotal }}</span>
                </div>
                <div
                  class="title"
                  :class="{
                    'text-warning':
                      liveVoucher.peopleTotal > liveVoucher.peopleLeft,
                  }"
                >
                  {{ liveVoucher.peopleLeft }} Left
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-6">
              <div class="counter-box color-white">
                <div class="sub">Available Funds (₦)</div>
                <div class="number text-success">
                  <span class="counter">{{
                    `${liveOwner.wallet
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                  }}</span>
                </div>
                <div class="title">Wallet</div>
              </div>
            </div>
            <div class="col-lg-3 col-6">
              <div class="counter-box color-white">
                <div class="sub">Expiry</div>
                <div class="number">
                  <span
                    class="suffix"
                    :class="{
                      'text-danger': timer > liveVoucher.expiredAt.toMillis(),
                    }"
                    >{{
                      new Date(liveVoucher.expiredAt.toMillis()).toLocaleString(
                        undefined,
                        {
                          month: "short",
                          day: "numeric",
                        }
                      )
                    }}</span
                  >
                </div>
                <div
                  class="title"
                  :class="{
                    'text-danger': timer > liveVoucher.expiredAt.toMillis(),
                  }"
                >
                  By
                  {{
                    new Date(liveVoucher.expiredAt.toMillis()).toLocaleString(
                      undefined,
                      {
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                      }
                    )
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <span id="chartSection"></span>
    <section class="section background-grey spdtb">
      <div class="container">
        <div class="row">
          <div class="col-lg-4">
            <div class="pdr30">
              <div class="heading mb32">
                <div class="heading-sub color-navy">CHART</div>
                <h2 class="heading-title size-l">Token</h2>
                <div class="heading-desc">
                  <span class="mt-5">
                    <DoughnutChart
                      ref="doughnutRef"
                      :chartData="testData"
                      :options="options"
                    />
                  </span>
                </div>
              </div>
              <div class="button-wrap">
                <!-- <a
                  href="contact-01.html"
                  class="button text color-navy"
                  title="Know More"
                  >Know More <i class="las la-long-arrow-alt-right"></i
                ></a> -->
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="heading align-center">
              <h2 class="heading-title">All Status Review</h2>
              <p class="heading-desc" v-if="liveVoucher">
                {{ liveVoucher.peopleTotal }} User-Token Updates
              </p>
            </div>
            <div class="table-responsive">
              <table class="full-plan">
                <thead>
                  <tr>
                    <th>
                      <div class="name">Status</div>
                    </th>
                    <th>
                      <div class="name">Token Activated</div>
                    </th>
                    <th>
                      <div class="name">Meal Ordered</div>
                    </th>
                    <th>
                      <div class="name">Order Authorized</div>
                    </th>
                  </tr>
                </thead>
                <tbody
                  v-if="
                    dOrders &&
                    liveVoucher &&
                    pOrders &&
                    rOrders &&
                    cOrders &&
                    sOrders
                  "
                >
                  <tr v-if="sOrders.length == 0">
                    <td class="title">Delivered</td>
                    <td><i class="las la-minus"></i></td>
                    <td><i class="las la-minus"></i></td>
                    <td><i class="las la-minus"></i></td>
                  </tr>
                  <tr v-else>
                    <td colspan="4" class="colspan-full s-del">
                      <span
                        class="my-cursor-clicker"
                        @click.prevent="scrollSmooth('dashboard')"
                        >Delivered ({{ sOrders.length }})</span
                      >
                    </td>
                  </tr>
                  <tr v-for="order in sOrders" :key="order.oid">
                    <td class="title">
                      {{ order.orderName }} ({{ order.orderPhone }})
                      {{ order.orderSpecial }}
                    </td>
                    <td class="is-active"><i class="las la-check"></i></td>
                    <td class="is-active"><i class="las la-check"></i></td>
                    <td class="is-active">
                      <i class="las la-check"></i><i class="las la-plus"></i
                      ><i class="las la-truck"></i>
                    </td>
                  </tr>
                  <tr v-if="cOrders.length == 0">
                    <td class="title">Authorized</td>
                    <td><i class="las la-minus"></i></td>
                    <td><i class="las la-minus"></i></td>
                    <td><i class="las la-minus"></i></td>
                  </tr>
                  <tr v-else>
                    <td colspan="4" class="colspan-full s-aut">
                      <span
                        class="my-cursor-clicker"
                        @click.prevent="scrollSmooth('dashboard')"
                        >Authorized ({{ cOrders.length }})</span
                      >
                    </td>
                  </tr>
                  <tr v-for="order in cOrders" :key="order.oid">
                    <td class="title">
                      {{ order.orderName }} ({{ order.orderPhone }})
                      {{ order.orderSpecial }}
                    </td>
                    <td class="is-active"><i class="las la-check"></i></td>
                    <td class="is-active"><i class="las la-check"></i></td>
                    <td class="is-active"><i class="las la-check"></i></td>
                  </tr>
                  <tr v-if="rOrders.length == 0">
                    <td class="title" v-if="rOrders">Ordered</td>
                    <td><i class="las la-minus"></i></td>
                    <td><i class="las la-minus"></i></td>
                    <td><i class="las la-minus"></i></td>
                  </tr>
                  <tr v-else>
                    <td colspan="4" class="colspan-full s-ord">
                      <span
                        class="my-cursor-clicker"
                        @click.prevent="scrollSmooth('topOrder')"
                        >Ordered ({{ rOrders.length }})
                        <i class="las la-cart-arrow-down text-primary"></i
                      ></span>
                    </td>
                  </tr>
                  <tr v-for="order in rOrders" :key="order.oid">
                    <td class="title">
                      {{ order.orderName }} ({{ order.orderPhone }})
                      {{ order.orderSpecial }}
                    </td>
                    <td class="is-active"><i class="las la-check"></i></td>
                    <td class="is-active"><i class="las la-check"></i></td>
                    <td class="is-active"><i class="las la-times"></i></td>
                  </tr>
                  <tr v-if="pOrders.length == 0">
                    <td class="title" v-if="pOrders">Activated</td>
                    <td><i class="las la-minus"></i></td>
                    <td><i class="las la-minus"></i></td>
                    <td><i class="las la-minus"></i></td>
                  </tr>
                  <tr v-else>
                    <td colspan="4" class="colspan-full s-act">
                      <span
                        class="my-cursor-clicker"
                        @click.prevent="scrollSmooth('dashboard')"
                      >
                        Activated ({{ pOrders.length }})
                      </span>
                    </td>
                  </tr>
                  <tr v-for="order in pOrders" :key="order.oid">
                    <td class="title">
                      {{ order.orderName }} ({{ order.orderPhone }})
                      {{ order.orderSpecial }}
                    </td>
                    <td class="is-active"><i class="las la-check"></i></td>
                    <td class="is-active"><i class="las la-times"></i></td>
                    <td class="is-active"><i class="las la-times"></i></td>
                  </tr>
                  <tr v-if="liveVoucher.peopleLeft == 0">
                    <td class="title s-un">Un-used</td>
                    <td><i class="las la-minus"></i></td>
                    <td><i class="las la-minus"></i></td>
                    <td><i class="las la-minus"></i></td>
                  </tr>
                  <tr v-else>
                    <td colspan="4" class="colspan-full s-un">
                      <span
                        class="my-cursor-clicker"
                        @click.prevent="scrollSmooth('dashboard')"
                      >
                        Un-used ({{ liveVoucher.peopleLeft }})
                      </span>
                    </td>
                  </tr>
                  <tr v-if="liveVoucher.peopleLeft > 0">
                    <td class="title">
                      {{ liveVoucher.peopleLeft }} Un-used slots in Token
                    </td>
                    <td class="is-active"><i class="las la-times"></i></td>
                    <td class="is-active"><i class="las la-times"></i></td>
                    <td class="is-active"><i class="las la-times"></i></td>
                  </tr>
                  <tr v-if="dOrders.length == 0">
                    <td class="title" v-if="dOrders">Deleted</td>
                    <td><i class="las la-minus"></i></td>
                    <td><i class="las la-minus"></i></td>
                    <td><i class="las la-minus"></i></td>
                  </tr>
                  <tr v-else>
                    <td colspan="4" class="colspan-full s-x">
                      <span
                        class="my-cursor-clicker"
                        @click.prevent="scrollSmooth('dashboard')"
                      >
                        Deleted ({{ dOrders.length }})
                      </span>
                    </td>
                  </tr>
                  <tr v-for="order in dOrders" :key="order.oid">
                    <td class="title">
                      {{ order.orderName }} ({{ order.orderPhone }})
                      {{ order.orderSpecial }}
                    </td>
                    <td class="is-active"><i class="las la-ban"></i></td>
                    <td class="is-active"><i class="las la-ban"></i></td>
                    <td class="is-active"><i class="las la-ban"></i></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <p><br /><br /></p>
    </section>
  </div>
</template>
     
<script>
import { computed, ref, watchEffect, onMounted } from "vue";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useStore } from "vuex";
import { Timestamp } from "firebase/firestore";
import { Chart, DoughnutController, ArcElement, Tooltip } from "chart.js";
Chart.register(DoughnutController, ArcElement, Tooltip);
import { DoughnutChart } from "vue-chart-3";
import sweetalert2 from "sweetalert2";

// Watcher
import watchOwner from "@/composables/firebaseDatabase/watchOwner";
import watchVoucher from "@/composables/firebaseDatabase/watchVoucher";
import watchVoucherOrderBox from "@/composables/firebaseDatabase/watchVoucherOrderBox";
import transactionCancelOrder from "@/composables/firebaseDatabase/transactionCancelOrder";

export default {
  name: "BonoVoucher",
  components: { DoughnutChart },
  props: ["id"],

  setup(props) {
    const menus = ref(null);
    const store = useStore();
    const isSubmiting = ref(false);
    const isDeleting = ref(false);
    const isCopy = ref(false);
    const timer = Timestamp.now().toMillis();
    const editOrder = ref(null);
    const openToken = ref(false);

    const { res: liveOwner, err: errOwner } = watchOwner(store.state.user.uid);
    const { res: liveVoucher, err: errVoucher } = watchVoucher(
      store.state.user.uid,
      props.id
    );
    const { res: liveOrder, err: errOrder } = watchVoucherOrderBox(
      store.state.user.uid,
      props.id
    );

    //scroll to top
    const element = document.getElementById("topmost");
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }

    watchEffect(() => {
      if (isCopy.value) {
        setTimeout(() => { isCopy.value = false }, 2000);
      }
    });

    watchEffect(() => {
      if (liveVoucher.value) {
        if (liveVoucher.value.peopleTotal == liveVoucher.value.peopleLeft) {
          openToken.value = true;
        }
        if (liveVoucher.value.peopleTotal != liveVoucher.value.peopleLeft) {
          openToken.value = false;
        }
      }
    });

    const handleOrder = async () => {
      if (rOrders.value && liveVoucher.value) {
        // loader
        isSubmiting.value = true;

        // assignments
        const orderIds = [];
        const voucherId = liveVoucher.value.vid;

        // load orders
        rOrders.value.forEach((order) => {
          orderIds.push(order.oid);
        });

        // speak to the cloud
        const cloudFunctions = getFunctions();
        const startSend = httpsCallable(cloudFunctions, "sendOrder");
        startSend({ orderIds, voucherId })
          .then((result) => {
            // Read result of the Cloud Function.
            const data = result.data;
            //scroll down to chart
            const element = document.getElementById("chartSection");
            if (element && data.succ !== 0) {
              element.scrollIntoView({ behavior: "smooth", block: "start" });
            } else {
              //error alert
              new sweetalert2({
                title: `You don't have enough money to complete transaction`,
                text: "Load your wallet to continue!",
              });
            }
            // loader
            isSubmiting.value = false;
          })
          .catch((error) => {
            // Getting the Error details.
            const code = error.code;
            const message = error.message;
            const details = error.details;
            // ...
            // loader
            isSubmiting.value = false;
          });
      } else {
        //console.log("error");
      }
    };

    const handleEdit = (id) => {
      if (id === "back") {
        liveOrder.value.forEach((order) => {
          order.editOrder = false;
        });
        editOrder.value = null; //clear memory
      } else if (id === "confirm") {
        isDeleting.value = true; // loader
        const oS = [];
        liveOrder.value.forEach((order) => {
          if (order.id === editOrder.value) {
            oS.push({ ...order }); // This creates a shallow copy of the order object and adds it to the oS array.
          }
        });

        (async () => {
          let newOrder = {
            status: "d", //pending, requested, completed, successful, saved (z), deleted
          };

          // load results
          const { res: cancelOrder, err: errCancelOrder } =
            await transactionCancelOrder(
              oS[0].owner,
              oS[0].vid,
              oS[0].oid,
              newOrder
            );

          if (cancelOrder.value) {
            //clear memory
            editOrder.value = null;
          }
        })();

        isDeleting.value = true; //loader
      } else {
        editOrder.value = id; //update id
        liveOrder.value.forEach((order) => {
          if (order.id === id) {
            order.editOrder = true;
          } else {
            order.editOrder = false;
          }
        });
      }
    };

    const copyToken = computed(() => {
      if (liveVoucher.value) {
        const t = `${liveVoucher.value.message}\n\nhttps://mealbono.com/${liveVoucher.value.vid}\n\n\n${liveVoucher.value.sender}`;
        return t;
      }
    });

    const pOrders = computed(() => {
      if (liveOrder.value) {
        return liveOrder.value.filter((orderStage) => orderStage.status == "p");
      }
    });

    const rOrders = computed(() => {
      if (liveOrder.value) {
        const c = liveOrder.value.filter(
          (orderStage) => orderStage.status == "r"
        );
        c.forEach((z) => {
          if (z.id == editOrder.value) {
            z.editOrder = true;
          }
        });
        return [...c].sort((b, a) => {
          return a.requestCreatedAt.toMillis() - b.requestCreatedAt.toMillis();
        });
      }
    });

    const rOrdersTotal = computed(() => {
      if (liveOrder.value) {
        let t = 0;
        liveOrder.value.forEach((total) => {
          if (total.status == "r") {
            t = Number(total.mealPrice) + t + 650;
          }
        });
        return t;
      }
    });

    const cOrders = computed(() => {
      if (liveOrder.value) {
        return liveOrder.value.filter(
          (orderStage) => orderStage.status == "c" || orderStage.status == "q"
        ); //ordered: completed or on queue
      }
    });

    const sOrders = computed(() => {
      if (liveOrder.value) {
        return liveOrder.value.filter((orderStage) => orderStage.status == "s"); //delivered
      }
    });

    const dOrders = computed(() => {
      if (liveOrder.value) {
        return liveOrder.value.filter((orderStage) => orderStage.status == "d"); //removed
      }
    });

    //Pie Chart
    const doughnutRef = ref();

    const options = ref({
      responsive: true,
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: true,
          text: "Token Distribution",
        },
      },
    });

    const testData = computed(() => {
      if (
        liveVoucher.value &&
        pOrders.value &&
        rOrders.value &&
        cOrders.value &&
        dOrders.value &&
        sOrders
      ) {
        return {
          labels: [
            "Deleted Orders",
            "Un-used Slots",
            "Activated Token",
            "Meals Ordered",
            "Authorized Orders",
            "Delivered Orders",
          ],
          datasets: [
            {
              data: [
                dOrders.value.length,
                liveVoucher.value.peopleLeft,
                pOrders.value.length,
                rOrders.value.length,
                cOrders.value.length,
                sOrders.value.length,
              ],
              backgroundColor: [
                "#d30f0f",
                "#080808fa",
                "#7d3c98",
                "#FFA500",
                "#1a40bc",
                "#117a65",
              ],
            },
          ],
        };
      } else {
        return {
          labels: ["Chart Loading..."],
          datasets: [
            {
              data: [10],
              backgroundColor: ["#77CEFF"],
            },
          ],
        };
      }
    });

    const scrollSmooth = (id) => {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    };

    const copyToClipboard = () => {
      if (copyToken.value) {
        // Create a temporary element to facilitate copying
        const tempElement = document.createElement("textarea");
        tempElement.value = copyToken.value;

        // Make the element hidden
        tempElement.style.position = "fixed";
        tempElement.style.opacity = 0;

        // Append the element to the DOM
        document.body.appendChild(tempElement);

        // Select and copy the text from the element
        tempElement.select();
        document.execCommand("copy");

        // Remove the temporary element
        document.body.removeChild(tempElement);

        // Optionally, you can show a success message to the user
        //alert("Token Message copied to clipboard");
        // Change icon color
        isCopy.value = true;
      }
    };

    return {
      handleOrder,
      handleEdit,
      isSubmiting,
      isDeleting,
      isCopy,
      menus,
      timer,
      openToken,
      liveOwner,
      liveVoucher,
      liveOrder,
      pOrders,
      rOrders,
      rOrdersTotal,
      cOrders,
      sOrders,
      dOrders,
      testData,
      doughnutRef,
      options,
      scrollSmooth,
      copyToClipboard,
    };
  },
};
</script>
   
<style scoped>
.s-del {
  color: #117a65;
}
.s-aut {
  color: #1a40bc;
}
.s-ord {
  color: #ffa500;
}
.s-act {
  color: #7d3c98;
}
.s-x {
  color: #d30f0f;
}
.s-un {
  color: #080808fa;
}
.my-cursor-clicker {
  cursor: pointer;
}
.custom-yellow-bg {
  background-color: #fcf3cf;
}
.text-white-custom {
  color: white;
}
.box.border-danger {
  border-right-width: 20%;
  border-right-style: solid;
}
</style>