import { ref, watchEffect } from 'vue';
import { db } from '@/firebase/config';
import { collection, doc, onSnapshot } from 'firebase/firestore';


const watchVoucherOrderBox = (ownerId, voucherId) => {

    // Start   
    const res = ref(null),
          err = ref(null);   

   // Hard-coded variables
   const COLLECTION_NAME = 'owner';   
   const OWNER_DOC = ownerId;
   const SUB_COLLECTION_NAME = 'voucher';
   const VOUCHER_DOC = voucherId;
   const SUB_SUB_COLLECTION_NAME = 'orderBox';


    try {

        // Firebase magic
        const voucherDocRef = doc(db, COLLECTION_NAME, OWNER_DOC, SUB_COLLECTION_NAME, VOUCHER_DOC);
        const orderBoxCollectionRef = collection(voucherDocRef, SUB_SUB_COLLECTION_NAME);

        const unsub = onSnapshot(orderBoxCollectionRef, (snapshot) => {
            const orders = [];

            snapshot.forEach((doc) => {

            orders.push({ ...doc.data(), id: doc.id, editOrder: false });

            });

            res.value = orders;

        }, (error) => {
            // Handle the error here
            err.value = error.message;
        });

        watchEffect((onInvalidate) => {
            onInvalidate(() => unsub());
        });

    } catch (error) {
        if (error.code === 'permission-denied') {
            err.value = 'Permission denied';
        } else {
            err.value = 'Error getting Orders';
        }
    }

    return { res, err };
};


export default watchVoucherOrderBox;
