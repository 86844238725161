import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'

//css
import '@/assets/libs/line-awesome/css/line-awesome.min.css';
import '@/assets/libs/bootstrap/css/bootstrap.min.css';
import '@/assets/libs/swiper/swiper-bundle.min.css';
import '@/assets/css/style.css';

createApp(App).use(store).use(router).mount('#app')




