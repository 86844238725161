<template>
  <div class="site-content pt0 pb0 pt-5">
    <section class="section spdt mt-5">
      <div class="container">
        <div class="heading align-left">
          <div class="heading-sub color-navy">Admin Area</div>
          <div class="heading-sub">
            Manage Orders<i class="las la-shopping-bag la-2x"></i>
          </div>
        </div>
      </div>
    </section>

    <div class="container mt-5">
      <h4 class="heading-title size-2 text-danger" v-if="liveOwner && sortedOrders">
        Pending Orders <em>({{ sortedOrders.length }})</em>
      </h4>

      <div class="row pt-2">
        <section class="section-posttype">
          <div class="posttype">
            <div v-for="order in sortedOrders" :key="order.oid">
              <div class="box">
                <div class="content">
                  <h3 class="title">{{ `${order.orderName} (${order.orderPhone})` }}</h3>
                  <div class="desc pb-2" v-if="order.orderSpecial">
                    <i class="las la-users" title="Group"></i> {{ order.orderSpecial }}
                  </div>
                  <h4 class="title">
                    {{
                      new Date(order.requestCreatedAt.toMillis()).toLocaleString(
                        undefined,
                        {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                          weekday: "long",
                          hour: "numeric",
                          minute: "numeric",
                          hour12: true,
                        }
                      )
                    }}
                  </h4>
                  <div class="desc">
                    {{ `${order.mealOrder} (${order.vendor})` }}
                  </div>
                 
                </div>
                <div class="button-wrap">
                  <router-link
                    :to="{ name: 'BonoVoucher', params: { id: order.vid } }"
                    class="button fullfield"
                    title="Detail"
                    >Review &#187;</router-link
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="action align-center mt60">
            <p>...</p>
            <br /><br />
          </div>
        </section>
      </div>
    </div>
  </div>
  <!-- SITE CONTENT -->
</template>
       
    <script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { Timestamp } from "firebase/firestore";

// Watcher
import watchOwner from "@/composables/firebaseDatabase/watchOwner";
import watchVouchersHistory from "@/composables/firebaseDatabase/watchVouchersHistory";
import watchOrderBox from "@/composables/firebaseDatabase/watchOrderBox";
import watchOwnerActiveVouchers from "@/composables/firebaseDatabase/watchOwnerActiveVouchers";

export default {
  name: "BonoOrders",

  setup() {
    const store = useStore();
    const timer = Timestamp.now().toMillis();
    const displayCat = ref("All");

    const { res: liveOwner, err: errOwner } = watchOwner(store.state.user.uid);
  
    const { res: liveOrderBox, err: errOrder } = watchOrderBox(
      store.state.user.uid
    );

    //scroll to top
    const element = document.getElementById("topmost");
    if (element) {
    element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
    
    const sortedOrders = computed(() => {
    if (liveOrderBox.value) {
        return [...liveOrderBox.value].sort((b, a) => {
          return a.requestCreatedAt.toMillis() - b.requestCreatedAt.toMillis();
        });
      }
    });

    return {
      timer,
      liveOwner,
      sortedOrders,
    };
  },
};
</script>
     
  <style>
.text-orange {
  color: orange;
}

.item a:hover {
  color: red;
}

.custom-skyblue-bg {
  background-color: #eef2f7;
}

.custom-yellow-bg {
  background-color: #fcf3cf;
}

.my-cursor-clicker {
  cursor: pointer;
}
</style>