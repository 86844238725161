// Transaction Create Voucher
import { doc, runTransaction, increment, collection } from 'firebase/firestore';
import { db } from '@/firebase/config';
import { ref } from 'vue';

const transactionCreateVoucher = async (userDocId, document) => {
    // Start
    const res = ref(null);
    const err = ref(null);

    // Hard-coded variables
    const COLLECTION_NAME = 'owner';
    const SUB_COLLECTION_NAME = 'voucher';
    const SUB_COLLECTION_NAME_R = 'receipt';
    const INCREASE_VOUCHER_COUNT = 1;
    const TOTAL_BUDGET = document.amount * document.peopleTotal; // this should equal amount paid in production
    const USER_DOC = userDocId;
    const DOCUMENT_DATA = document;

    // Firebase Variables
    const ownerRef = doc(db, COLLECTION_NAME, USER_DOC);
    const voucherRef = collection(ownerRef, SUB_COLLECTION_NAME);
    const receiptRef = collection(ownerRef, SUB_COLLECTION_NAME_R);

    try {
        await runTransaction(db, async (transaction) => {
            // Update voucher record
            transaction.update(ownerRef, { 
                voucher: increment(INCREASE_VOUCHER_COUNT), 
                deposit: increment(TOTAL_BUDGET), 
                wallet: increment(TOTAL_BUDGET) // ***** Edit Required in Production ***** Assuming payments on total budget was made 
            });

            // Create a new Receipt in the subcollection
            const newReceipt = doc(receiptRef);
            const receiptDocumentRef = doc(receiptRef, newReceipt.id);
            transaction.set(receiptDocumentRef, { 
                amount: TOTAL_BUDGET, 
                createdAt: DOCUMENT_DATA.createdAt, 
                type: 'w', 
                vid: newReceipt.id 
            });

            // Create a new Voucher in the subcollection
            const newVoucher = doc(voucherRef);
            const voucherDocumentRef = doc(voucherRef, newVoucher.id);
            transaction.set(voucherDocumentRef, { ... DOCUMENT_DATA, vid: newVoucher.id });
 
            // Output response
            res.value = newVoucher.id;
        });


    } catch (error) {

        if (error.code === "permission-denied") {
            // Permission error
            err.value = "Permission denied";
        } else {
            // Handle other non-permission errors here
            err.value = "Error creating VOUCHER, please try again";
        }

    }


    return { res, err }
};

export default transactionCreateVoucher;