import { ref } from 'vue';
import { db } from '@/firebase/config';
import { doc, collection, getDocs } from 'firebase/firestore';


const getReceipts = async (parentId) => {

  // Start  
  const res = ref(null),
        err = ref(null);

  // Variables
  const OWNER = 'owner';
  const OWNER_ID = parentId;
  const RECEIPT = 'receipt';  

  try {

    // Firebase magic
    const parentDocRef = doc(db, OWNER, OWNER_ID);
    const subColRef = collection(parentDocRef, RECEIPT);
    const querySnapshot = await getDocs(subColRef);

    const results = [];

    querySnapshot.forEach((doc) => {
      results.push({ ...doc.data(), id: doc.id });
    });

    res.value = results;

  } catch (error) {
    if (error.code === 'permission-denied') {
      err.value = 'Permission denied';
    } else {
      err.value = 'Error getting documents, please try again';
    }
  }


  return { res, err };


};


export default getReceipts;
