<template>
  <div class="site-content pt0 pb0 pt-5">
    <section class="section spdt mt-5">
      <div class="container">
        <div class="heading align-left">
          <div class="heading-sub color-navy">Admin Area</div>
          <div class="heading-sub">
            Manage Vouchers<i class="las la-tools la-2x"></i>
          </div>
        </div>
      </div>
    </section>

    <div class="container mt-5">
      <div class="heading mb32 d-lg-none" v-if="vCounter">
        <div class="heading-sub layout-02 bg-light">
          <a
            @click="switchDisplay('Active')"
            class="my-cursor-clicker"
            title="Active"
            >Active <span>({{ vCounter.active }})</span></a
          >

          <i
            :class="{
              'las la-check-circle text-primary': displayCat == 'Active',
            }"
          ></i>
        </div>
        &nbsp;
        <div class="heading-sub layout-02 bg-light">
          <a
            @click="switchDisplay('Expired')"
            class="my-cursor-clicker"
            title="Expired"
            >Expired <span>({{ vCounter.expired }})</span></a
          >
          <i
            :class="{
              'las la-check-circle text-primary': displayCat == 'Expired',
            }"
          ></i>
        </div>
        &nbsp;
        <div class="heading-sub layout-02 bg-light">
          <a @click="switchDisplay('All')" class="my-cursor-clicker" title="All"
            >All <span>({{ vCounter.all }})</span></a
          >
          <i
            :class="{
              'las la-check-circle text-primary': displayCat == 'All',
            }"
          ></i>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-9">
          <h4 class="heading-title size-2 text-orange pb-2" v-if="liveOwner">
            {{ displayCat }} Vouchers
          </h4>

          <section class="section-posttype">
            <div class="posttype">
              <div v-for="voucher in Vouchers" :key="voucher.vid">
                <div class="box">
                  <div class="content">
                    <h3 class="title">Meal-Token-{{ voucher.amount }}</h3>

                    <h4 class="title">
                      Created:
                      {{
                        new Date(voucher.createdAt.toMillis()).toLocaleString(
                          undefined,
                          {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                            weekday: "long",
                            hour: "numeric",
                            minute: "numeric",
                            hour12: true,
                          }
                        )
                      }}
                    </h4>
                    <div class="desc">
                      ({{
                        `₦${voucher.amount
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                      }}
                      x {{ voucher.peopleTotal }} people)
                    </div>
                  </div>
                  <div class="button-wrap">
                    <router-link
                      :to="{ name: 'BonoVoucher', params: { id: voucher.vid } }"
                      class="button fullfield"
                      :class="{
                        'fullfield-dark': voucher.expiredAt.toMillis() < timer,
                      }"
                      title="Detail"
                      >Detail</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="action align-center mt60">
              <p></p>
              <br /><br />
            </div>
          </section>
        </div>
        <div class="col-lg-3">
          <div class="sidebar d-none d-lg-block">
            <aside class="widget widget-category">
              <h3 class="widget-title"><u>Categories</u></h3>
              <div class="widget-content">
                <ul v-if="vCounter">
                  <li>
                    <a
                      @click="switchDisplay('Active')"
                      class="my-cursor-clicker"
                      title="Active"
                      >Active <span>({{ vCounter.active }})</span></a
                    >
                    <i
                      :class="{
                        'las la-check-circle text-primary':
                          displayCat == 'Active',
                      }"
                    ></i>
                  </li>
                  <li>
                    <a
                      @click="switchDisplay('Expired')"
                      class="my-cursor-clicker"
                      title="Expired"
                      >Expired <span>({{ vCounter.expired }})</span></a
                    >
                    <i
                      :class="{
                        'las la-check-circle text-primary':
                          displayCat == 'Expired',
                      }"
                    ></i>
                  </li>
                  <li>
                    <a
                      @click="switchDisplay('All')"
                      class="my-cursor-clicker"
                      title="All"
                      >All <span>({{ vCounter.all }})</span></a
                    >
                    <i
                      :class="{
                        'las la-check-circle text-primary': displayCat == 'All',
                      }"
                    ></i>
                  </li>
                </ul>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- SITE CONTENT -->
</template>
     
  <script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { Timestamp } from "firebase/firestore";

// Watcher
import watchOwner from "@/composables/firebaseDatabase/watchOwner";
import watchVouchersHistory from "@/composables/firebaseDatabase/watchVouchersHistory";

export default {
  name: "BonoHistory",

  setup() {
    const store = useStore();
    const timer = Timestamp.now().toMillis();
    const displayCat = ref("All");

    const { res: liveOwner, err: errOwner } = watchOwner(store.state.user.uid);
    const { res: liveVouchersHistory, err: errVoucher } = watchVouchersHistory(
      store.state.user.uid
    );

    //scroll to top
    const element = document.getElementById("topmost");
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }

    const switchDisplay = (cat) => {
      displayCat.value = cat;
    };

    const vCounter = computed(() => {
      let z = 0;
      let a = 0;
      let x = 0;

      if (liveVouchersHistory.value) {
        liveVouchersHistory.value.forEach((voucher) => {
          if (Timestamp.now().toMillis() < voucher.expiredAt.toMillis()) {
            a++;
          } else {
            x++;
          }
          z++;
        });
      }

      // Return an object with the counts
      return { all: z, active: a, expired: x };
    });

    const Vouchers = computed(() => {
      if (liveVouchersHistory.value) {
        if (displayCat.value == "All") {
          return liveVouchersHistory.value.slice().sort((b, a) => {
            return a.expiredAt.toMillis() - b.expiredAt.toMillis();
          });
        } else if (displayCat.value == "Active") {
          const c = liveVouchersHistory.value.filter(
            (expireTime) =>
              Timestamp.now().toMillis() < expireTime.expiredAt.toMillis()
          );
          return [...c].sort((b, a) => {
            return a.expiredAt.toMillis() - b.expiredAt.toMillis();
          });
        } else if (displayCat.value == "Expired") {
          const c = liveVouchersHistory.value.filter(
            (expireTime) =>
              Timestamp.now().toMillis() > expireTime.expiredAt.toMillis()
          );
          return [...c].sort((b, a) => {
            return a.expiredAt.toMillis() - b.expiredAt.toMillis();
          });
        }
      }
    });

    return {
      timer,
      displayCat,
      liveOwner,
      vCounter,
      switchDisplay,
      Vouchers,
    };
  },
};
</script>
   
<style>
.text-orange {
  color: orange;
}

.item a:hover {
  color: red;
}

.custom-skyblue-bg {
  background-color: #eef2f7;
}

.custom-yellow-bg {
  background-color: #fcf3cf;
}

.my-cursor-clicker {
  cursor: pointer;
}
</style>