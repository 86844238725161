<template>
  <span id="topTop"></span>
  <!-- BEGIN SITE MAIN -->
  <div class="site-content pt0 pb0">
    <section class="section-banner layout-10 opt120 overflow-hidden">
      <div class="shape-top">
        <svg
          width="820"
          height="156"
          viewBox="0 0 820 156"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M819.869 0C718.846 95.7944 572.637 156 410 156C247.363 156 101.154 95.7944 0.131531 0H819.869Z"
            fill="var(--sala-shade-background, $shade_background)"
          />
        </svg>
      </div>
      <div class="container pt-3 pt-lg-0">
        <div class="row flex-align-c">
          <div class="col-lg-6">
            <div class="heading mb32">
              <div class="heading-sub layout-02">
                <p>Meal Tokenization</p>
              </div>

              <h2 class="heading-title size-xl">
                Send <span><em>lunch</em></span> to anyone, anywhere.
              </h2>
            </div>

            <div class="button-wrap layout-02 mt32 pt-3">
              <a
                href="#"
                class="button fullfield-dark"
                title="Learn More..."
                @click.prevent="scrollSmooth('topHow')"
                >Show Me</a
              >
              <router-link
                :to="{ name: 'BonoServe' }"
                class="button fullfield xs-mb10"
                title="Get Started"
                >Get Me Started</router-link
              >
            </div>
          </div>
          <div class="col-lg-6 d-none d-lg-block">
            <img
              class="lg-mt32"
              src="@/assets/images/welcome.png"
              alt="Banner"
            /> <!-- (wow animate__fadeInDown) removed from img class --> 
          </div>
        </div>
      </div>

      <p><br /><br /><br /></p>
    </section>

    <span id="partners"></span>
    <div class="section-partner spdtb">
      <div class="container">
        <div class="heading align-center">
          <div class="heading-desc">
            We work with the finest restaurants <br />
            anywhere in the country.
          </div>
        </div>
        <div class="block-partner no-border">
          <div class="row">
            <div class="col-lg-2 col-6">
              <div class="partner-box">
                <img src="@/assets/img/partners/bk-grey.png" alt="Partner" />
              </div>
            </div>
            <div class="col-lg-2 col-6">
              <div class="partner-box">
                <img src="@/assets/img/partners/cr-grey.jpg" alt="Partner" />
              </div>
            </div>
            <div class="col-lg-2 col-6">
              <div class="partner-box">
                <img src="@/assets/img/partners/kfc-grey.png" alt="Partner" />
              </div>
            </div>
            <div class="col-lg-2 col-6">
              <div class="partner-box">
                <img src="@/assets/img/partners/kili-grey.jpg" alt="Partner" />
              </div>
            </div>
            <div class="col-lg-2 col-6">
              <div class="partner-box">
                <img src="@/assets/img/partners/tlz-grey.png" alt="Partner" />
              </div>
            </div>
            <div class="col-lg-2 col-6">
              <div class="partner-box">
                <img src="@/assets/img/partners/domi-grey.png" alt="Partner" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <span id="howItWorks"></span>
    <section class="section background-navy spdtb">
      <div class="container">
        <div class="heading heading-alway-white align-center mb32">
          <h2 class="heading-title size-l">
            MealBono is the easiest way to send meals to anyone, anywhere.
          </h2>
        </div>
        <div class="button-wrap align-center">
          <router-link
            :to="{ name: 'BonoServe' }"
            class="button fullfield-white"
            title="Get started"
            >Get Started &#187;</router-link
          >
        </div>
      </div>
    </section>

    <span id="topHow"></span>
    <section class="section pt-5">
      <div class="container">
        <div class="block-icon-box layout-09 item-grey">
          <div class="row">
            <div class="col-lg-4">
              <div class="icon-box">
                <div class="inner">
                  <div class="icon">
                    <span class="number">1</span>
                  </div>
                  <div class="content">
                    <h3 class="title">Generate Token</h3>
                    <p>
                      Start by generating a meal-Token within your budget (from
                      N2,700 only).
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="icon-box">
                <div class="inner">
                  <div class="icon">
                    <span class="number">2</span>
                  </div>
                  <div class="content">
                    <h3 class="title">Share Token</h3>
                    <p>
                      Send generated Token to the Recipient(s) - an indiviual or
                      a group of people.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="icon-box">
                <div class="inner">
                  <div class="icon">
                    <span class="number">3</span>
                  </div>
                  <div class="content">
                    <h3 class="title">Use Token</h3>
                    <p>
                      Finally, the recipient(s) logs in with Token and orders
                      any meal of choice (all in your name - delivery included).
                      Classy!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section spdt">
      <div class="container">
        <div class="heading align-center">
          <div class="heading mb32">
            <div class="heading-sub layout-02">
              <h2 class="heading-title size-l">
                Clients <em>love</em> MealBono
              </h2>
            </div>
          </div>
          <div class="heading-desc">See how our customers use our product</div>
        </div>
        <div class="row flex-align-c">
          <div class="col-lg-6">
            <div class="pdr100">
              <div class="images layout-02 lg-mb60">
                <div class="inner">
                  <img
                    class="img01"
                    src="@/assets/img/meetings.gif"
                    alt="Remote Meetings"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="pdl60 pdr100">
              <div class="heading mb24">
                <div class="heading-sub color-navy">
                  <em>for</em> Meetings & Trainings
                </div>
                <h2 class="heading-title">Refereshment Helps.</h2>
                <div class="heading-desc">
                  Staff will appreciate lunch during virtual meetings. Sending
                  them lunch in the comfort of their homes will help your
                  course.
                </div>
              </div>
              <div class="block-list layout-01 block-list-flex">
                <ul>
                  <li>
                    <i class="las la-check"></i>
                    <span>Generate a single meal-Token</span>
                  </li>
                  <li>
                    <i class="las la-check"></i>
                    <span>Share to all staff</span>
                  </li>
                  <li>
                    <i class="las la-check"></i>
                    <span
                      >Each staff orders meal of choice
                      <em>(delivery included)</em></span
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section spdt pb-5">
      <div class="container">
        <div class="row flex-align-c">
          <div class="col-lg-6 lg-order-2">
            <div class="pdl60 pdr100">
              <div class="heading mb24">
                <div class="heading-sub color-navy">
                  <em>for</em> Gifts & Giveaways
                </div>
                <h2 class="heading-title">Always Works.</h2>
                <div class="heading-desc">
                  Giving anyone or a group of people food always do the magic.
                  They will thank you so much.
                </div>
              </div>
              <div class="block-list layout-01 block-list-flex">
                <ul>
                  <li>
                    <i class="las la-check"></i>
                    <span>Generate a single meal-Token</span>
                  </li>
                  <li>
                    <i class="las la-check"></i>
                    <span>Share to recipient(s)</span>
                  </li>
                  <li>
                    <i class="las la-check"></i>
                    <span
                      >Each recipient orders meal of choice
                      <em>(delivery included)</em></span
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-6 lg-order-1">
            <div class="pdl100">
              <div class="images layout-02 lg-mb60">
                <div class="inner">
                  <img class="img01" src="@/assets/img/gifts.gif" alt="Image" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <span id="faqs"></span>
    <section class="section spdt pb-5 bg-light">
      <div class="container">
        <div class="heading align-center mb60">
          <h2 class="heading-title size-l">Frequently asked questions</h2>
        </div>
        <div class="block-accordion layout-02">    <!-- removed enable -->
          <div class="row" v-if="faqs">

            <div class="col-lg-6" v-for="faq in faqs" :key="faq.qid">
              <div class="accordion-item border-line">
                <h4 class="title" @click.prevent="faq.isOpen = !faq.isOpen">{{ faq.question }}</h4>
                <div class="mx-4 my-2" v-if="faq.isOpen">
                  <p>
                    {{ faq.answer }}
                  </p>
                </div>
              </div>
            </div>
          
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
  
<script>
import { ref } from "vue";

export default {
  name: "Welcome",

  setup() {
    const faqs = ref([
  {
    qid: 1,
    isOpen: false,
    question: 'What is MealBono?',
    answer: 'MealBono is an online platform that enables you to send food gifts and share meals with others using our innovative eVoucher system. You can use MealBono for various occasions, such as virtual meetings, training sessions, giveaways, or simply to brighten someone\'s day with a delicious meal.',
  },
  {
    qid: 2,
    isOpen: false,
    question: 'How does MealBono work?',
    answer: '1. Generate eVoucher: Start by creating a Meal-Token within your budget, starting from just N2,700.\n\n2. Share to recipient(s): Send the generated Token to the recipient via WhatsApp or by copying and pasting it.\n\n3. Order meal: The recipient can then log in using the Token and choose any meal they desire, with the meal being delivered in your name.',
  },
  {
    qid: 3,
    isOpen: false,
    question: 'Can I send meals to anyone in Nigeria?',
    answer: 'Yes! MealBono allows you to send meals to anyone in any city across Nigeria. Whether they\'re in Lagos, Abuja, or any other city, you can use MealBono to share the joy of a delicious meal.',
  },
  {
    qid: 4,
    isOpen: false,
    question: 'Is delivery included in the Meal-Token?',
    answer: 'Absolutely! When the recipient orders a meal using the eVoucher, the delivery is included. We want to ensure a hassle-free and delightful experience for both the sender and the recipient.',
  },
  {
    qid: 5,
    isOpen: false,
    question: 'What occasions can I use MealBono for?',
    answer: 'MealBono is perfect for a wide range of occasions, including virtual meetings, training sessions, corporate events, giveaways, appreciation gestures, and more. Anytime you want to share a meal and make someone\'s day better, MealBono is at your service.',
  },
  {
    qid: 6,
    isOpen: false,
    question: 'How can I get started with MealBono?',
    answer: 'Getting started is simple. Just visit our website - MealBono.com - , create a Meal Voucher within your budget, share it with the recipient, and let them enjoy a delightful meal. Join us in the joy of giving and sharing through the power of food.\n\nFor any further inquiries or assistance, feel free to reach out to our dedicated customer support team. MealBono – Sharing meals, creating connections.',
  },
])

    const scrollSmooth = (id) => {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    };

    return { faqs, scrollSmooth };
  },
};
</script>
  
<style scoped>
.my-cursor-clicker {
  cursor: pointer;
}
.custom-yellow-bg {
  background-color: #fcf3cf;
}
.fade-in-down {
  opacity: 0;
  transform: translateY(-50px);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}
</style>