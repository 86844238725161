<template>
  <!-- START -->
  <HeaderMain
    :userName="userName"
    :userEmail="userEmail"
    v-if="showNav == 'main'"
  />
  <HeaderBono
    :userName="userName"
    :userEmail="userEmail"
    v-if="showNav == 'bono'"
  />
  <HeaderBonoCred v-if="showNav == 'cred'" />
  <HeaderMeal :welcomeMessage="welcomeMessage" v-if="showNav == 'meal'" />
  <!-- END -->
</template>


<script>
import { computed, ref, watchEffect } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

// Header Components
import HeaderMain from "@/components/headers/Main.vue";
import HeaderBono from "@/components/headers/Bono.vue";
import HeaderBonoCred from "@/components/headers/BonoCred.vue";
import HeaderMeal from "@/components/headers/Meal.vue";

export default {
  name: "Home",
  components: { HeaderMain, HeaderBono, HeaderBonoCred, HeaderMeal },
  setup() {
    const userEmail = ref(null);
    const userName = ref(null);
    const welcomeMessage = ref(null);

    // route
    const route = useRoute();

    // router
    const router = useRouter();

    // store
    const store = useStore();

    const showNav = computed(() => {
      if (route.path.includes("meal/")) {
        return "meal";
      } else if (route.path.includes("bono/")) {
        if (
          route.path.includes("bono/login") ||
          route.path.includes("bono/start")
        ) {
          return "cred";
        } else {
          return "bono";
        }
      } else {
        return "main";
      }
    });

    watchEffect(() => {
      if (store.state.user) {
        userEmail.value = store.state.user.email;
        userName.value = store.state.user.displayName;
      } else {
        userEmail.value = null;
        userName.value = null;
        // log user out of admin area
        if (route.path.includes("bono") && !route.path.includes("start")) {
          router.push({ name: "BonoLogin" });
        }
      }
    });

    watchEffect(() => {
      if (store.state.cart) {
        welcomeMessage.value = `Welcome ${store.state.cart.name}`;
      }
    });

    const scrollSmooth = (id) => {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    };

    return {
      showNav,
      userEmail,
      userName,
      welcomeMessage,
      scrollSmooth,
    };
  },
};
</script>

<style>
</style>