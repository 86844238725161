// Transaction Start Order
import { doc, runTransaction, increment, collection, setDoc } from 'firebase/firestore';
import { Timestamp } from "firebase/firestore";
import { db } from '@/firebase/config';
import { ref } from 'vue';

const transactionSendOrder = async (ownerId, voucherId, orderId, document, orderAuth) => {

    // Start
    const res = ref(null);
    const err = ref(null);

    // Hard-coded variables
    const COLLECTION_NAME = 'owner';
    const SUB_COLLECTION_NAME = 'voucher';
    const OWNER_DOC = ownerId;
    const VOUCHER_DOC = voucherId;
    const ORDER_DOC = orderId;
    const SUB_SUB_COLLECTION_NAME = 'orderBox';
    const SUB_SUB_COLLECTION_NAME_Q = 'orderQueue';
    const INCREASE_PEOPLE_COUNT = 1;
    const DECREASE_PEOPLE_COUNT = -1;
    let DOCUMENT_DATA = document; // global variable
    let ORDER_AUTH = orderAuth; // global variable

    // Firebase Variables
    const ownerRef = doc(db, COLLECTION_NAME, OWNER_DOC);
    const voucherRef = doc(db, COLLECTION_NAME, OWNER_DOC, SUB_COLLECTION_NAME, VOUCHER_DOC);
    const orderRef = doc(db, COLLECTION_NAME, OWNER_DOC, SUB_COLLECTION_NAME, VOUCHER_DOC, SUB_SUB_COLLECTION_NAME, ORDER_DOC);
    const queueRef = collection(voucherRef, SUB_SUB_COLLECTION_NAME_Q);
    const newQueue = doc(queueRef);
    const queueDocumentRef = doc(queueRef, newQueue.id);


    if (ORDER_AUTH == 'n') {

    
        try {


            await runTransaction(db, async (transaction) => {

                    // owner
                    const owner = await transaction.get(ownerRef);
                    let wallet = owner.data().wallet;

                    // voucher
                    const voucher = await transaction.get(voucherRef);
                    let budget = voucher.data().amount;

                    if (wallet > budget) {

                    // move money from wallet to escrow
                    transaction.update(ownerRef, {
                    wallet: increment(-budget),
                    escrow: increment(+budget),
                    });

                    // Update voucher record
                    transaction.update(voucherRef, { orderQueued: increment(INCREASE_PEOPLE_COUNT), orderPending: increment(DECREASE_PEOPLE_COUNT) });

                    // Update voucher record
                    transaction.update(orderRef, DOCUMENT_DATA);
                    
                    // Create orderQueue
                    transaction.set(queueDocumentRef, {
                    qid: newQueue.id,
                    owner: OWNER_DOC,
                    vid: VOUCHER_DOC,
                    oid: ORDER_DOC,
                    createdAt: DOCUMENT_DATA.queueCreatedAt,
                    });

                    // Output response
                    res.value = ORDER_AUTH;

                }else{
                    
                    // send order to sender to foot bill
                    ORDER_AUTH = 'y'; 
                    DOCUMENT_DATA.status = 'r'; 
                    DOCUMENT_DATA.orderAuth = 'y';
                    delete DOCUMENT_DATA.queueCreatedAt;

                } 


            });


    } catch (error) {

        if (error.code === "permission-denied") {
            // Permission error
            err.value = "Permission denied";
        } else {
            // Handle other non-permission errors here
            err.value = "Error Completing Order, please try again";
        }

    }


    }



    if (ORDER_AUTH == 'y') {


    try {


            await runTransaction(db, async (transaction) => {

                    // Update voucher record
                    transaction.update(voucherRef, { orderRequested: increment(INCREASE_PEOPLE_COUNT), orderPending: increment(DECREASE_PEOPLE_COUNT) });

                    // Update voucher record
                    transaction.update(orderRef, DOCUMENT_DATA);

                    // Output response
                    res.value = ORDER_AUTH;

            });


    } catch (error) {

        if (error.code === "permission-denied") {
            // Permission error
            err.value = "Permission denied";
        } else {
            // Handle other non-permission errors here
            err.value = "Error Sending Order, please try again";
        }

    }


 }  

 
    return { res, err }
};


export default transactionSendOrder;