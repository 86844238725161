<template>
  <!-- BEGIN SITE HEADER -->
  <header id="header" class="site-header is-transparent">
    <div class="container-fluid">
      <div class="row">
        <!-- removed flex ( flex-align-c inner )-->
        <div class="col-lg-3 col-6">
          <div class="header-left flex flex-align-c">
            <div class="logo">
              <router-link :to="{ name: 'MealHome' }">
                <img
                  src="@/assets/img/mealbono.png"
                  alt="MealBono"
                  class="img-fluid"
                />
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-0 text-center">
          <div class="header-center">
            <div class="main-menu">
              <div class="menu-action">
                <span class="item menu-back"
                  ><i class="las la-arrow-left"></i
                ></span>
                <span class="item menu-close"
                  ><i class="las la-times"></i
                ></span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-6">
          <div class="header-right flex flex-align-c flex-content-e">
            <div class="customer">
              <a name="welcome" class="text-dark d-none d-lg-block">
                <span> {{ welcomeMessage }}</span>
              </a>
              <a
                href="#"
                class="text-dark d-lg-none"
                @click.prevent="showWelcome"
              >
                <i class="las la-user-circle" :title="welcomeMessage"></i>
              </a>
            </div>
            <div class="buttons">
              <span
                class="button fullfield-dark my-cursor-clicker"
                @mouseover="turnOnHover = true"
                @mouseleave="turnOnHover = false"
                @click="handleDelete"
                ><i
                  class="las la-times-circle text-warning"
                  :class="{ 'text-white': turnOnHover }"
                  title="Exit Order"
                ></i
                ><span
                  class="text-warning"
                  :class="{ 'text-white': turnOnHover }"
                  title="Exit Order"
                  >&#10008; Exit</span
                ></span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
  <!-- END SITE HEADER -->
</template>
  
  <script>
import { computed, ref, watchEffect } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import sweetalert2 from "sweetalert2";

export default {
  props: ["welcomeMessage"],
  setup(props) {
    const welcomeMessage = ref("");
    const turnOnHover = ref(false);

    // route
    const route = useRoute();

    // router
    const router = useRouter();

    // store
    const store = useStore();

    watchEffect(() => {
      welcomeMessage.value = props.welcomeMessage;
    });

    const showWelcome = () => {
      new sweetalert2({
        title: welcomeMessage.value,
        text: "Feel free & Enjoy!",
      });
    };

    const handleDelete = () => {
      new sweetalert2({
        title: "Are you sure you want to exit current order?",
        text: "Action required for new meal-Token.",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          //clear cart
          if (store.state.cart) {
            store.commit("updateCart", null); //null cart
          }
        }
      });
    };

    const scrollSmooth = (id) => {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    };

    return {
      welcomeMessage,
      showWelcome,
      scrollSmooth,
      turnOnHover,
      handleDelete,
    };
  },
};
</script>
  
  <style>
.my-cursor-clicker {
  cursor: pointer;
}
.no-hover-effect {
  background-color: red; /* Change the background color to something else */
  color: black; /* Change the text color to something else */
}
</style>