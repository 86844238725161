<template>
  <section class="page-title layout-02">
    <div class="container">
      <div class="inner align-center">
        <h1 class="title">Sign in</h1>
        <div class="desc">
          New user?
          <router-link :to="{ name: 'BonoStart' }" title="Create an account"
            >Create an account</router-link
          >
        </div>
      </div>
    </div>
  </section>

  <div class="site-content">
    <div class="container">
      <form @submit.prevent="handleSignIn" class="sign-in">
        <div v-if="error">
          <p class="alert alert-warning text-danger text-center pb-4">
            <i class="las la-exclamation la-2x"></i> {{ error }}
          </p>
        </div>

        <div class="field-input">
          <label for="email">Email*</label>
          <input
            type="email"
            class="form-control"
            v-model="email"
            placeholder="ex: you@gmail.com"
          />
        </div>
        <div class="field-input field-password">
          <label for="password">Password*</label>
          <input
            type="password"
            class="form-control"
            v-model="password"
            placeholder="********"
          />
          <i class="lar la-eye view-password"></i>
        </div>

        <div class="field-submit">
          <input
            type="submit"
            :value="isSubmiting ? 'Processing...' : 'Enter'"
            :disabled="isSubmiting"
          />
        </div>
      </form>
    </div>
  </div>
</template>


<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

import getOwner from "@/composables/firebaseDatabase/getOwner";

export default {
  name: "BonoLogin",

  setup() {
    const OWNER_COLLECTION = "owner";
    const router = useRouter();
    const isSubmiting = ref(false);
    const email = ref("");
    const password = ref("");
    const error = ref("");
 
    // load store
    const store = useStore();

    //scroll to top
    const element = document.getElementById("topmost");
    if (element) {
    element.scrollIntoView({ behavior: "smooth", block: "start" });
    }

    // log user out
    store.dispatch("logOut");

    // signin
    const handleSignIn = async () => {
      // loader
      isSubmiting.value = true;
      error.value = "";

      if (!email.value || !password.value) {
        error.value = "Sign-In Field(s) Empty";
      } else {
        try {
          await store.dispatch("signIn", {
            email: email.value,
            password: password.value,
          });

          // Initiate User
          const { res, err } = await getOwner(
            OWNER_COLLECTION,
            store.state.user.uid
          );

          if (res.value) {
            //success proceed
            router.push({ name: "BonoHome" });
          } else {
            if (err.value == "Owner not found") {
              //complete reg
              store.commit("sendMessenger", "complete signup");
              router.push({ name: "BonoStart" });
            } else {
              //error signed user out again
              error.value = err.value;
              // log user out
              store.dispatch("logOut");
            }
          }
        } catch (err) {
          error.value = err.message;
        }

      email.value = "";
      password.value = "";

      }

      isSubmiting.value = false;
    };

    return {
      error,
      isSubmiting,
      email,
      password,
      handleSignIn,
    };
  },
};
</script>

<style>
/* Style the spinner */
.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>