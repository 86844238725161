<template v-if="isCheckingVoucher">
  <div class="pt-2">
    <div class="container">
      <div class="row flex-align-c">
        <div class="col-lg-6">
          <div class="logo">
            <router-link to="/">
              <img
                src="@/assets/img/mealbono.png"
                alt="MealBono"
                class="img-fluid"
              />
            </router-link>
          </div>
        </div>
        <div class="col-lg-6"></div>

        <div class="col-lg-6">
          <div class="maintainance-left pdr80">
            <div class="heading">
              <p class="desc" v-if="voucher">
                <strong
                  ><span
                    class="text-primary text-uppercase"
                    :class="{ h5: voucher.sender.length < 5 }"
                    >{{ voucher.sender }}</span
                  ></strong
                >
                <i class="las la-user-tag la-2x" title="Sponsor"></i>
              </p>
              <h2 class="heading-title size-xl">
                Grab Your
                <br />
                <span>Meal</span>.
              </h2>
              <div class="heading-desc">
                Sending a meal? Click
                <router-link to="/" title="Go Home" class="text-warning"
                  >here</router-link
                >
              </div>

              <!--
              <div class="heading-desc">
                To make somethings right we need some time to rebuild. Get
                notified when we are done.
              </div>
              -->
            </div>
          </div>
        </div>

        <div class="col-lg-6 pt-3 pt-lg-0">
          <div class="block-banner background-shade-2 bg-warning">
            <div class="row flex-align-c">
              <div class="lg-pdl30 lg-pdr30 lg-pdt30 pdl60 pdr60 my-lg-5">
                <div v-if="error">
                  <p class="alert alert-warning text-danger text-center pb-3">
                    <i class="las la-exclamation la-2x"></i> {{ error }}
                  </p>
                </div>

                <template v-if="voucher">
                  <form @submit.prevent="handleSubmit">
                    <div class="form-group text-white mb-3">
                      <div class="col-auto">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <div class="input-group-text">
                              <i class="las la-user-alt la-2x"></i>
                            </div>
                          </div>
                          <input
                            type="text"
                            class="form-control"
                            :placeholder="
                              voucher.receiver
                                ? `${voucher.receiver}`
                                : 'Full name'
                            "
                            v-model="userName"
                            :disabled="voucher.receiver"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="form-group text-white mb-3">
                      <div class="col-auto">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <div class="input-group-text">
                              <i class="las la-phone-volume la-2x"></i>
                            </div>
                          </div>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="080XXXXXXX"
                            v-model="userPhone"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="form-group text-white mb-3" v-if="userSpecial">
                      <div class="col-auto">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <div class="input-group-text">
                              <i class="las la-passport la-2x"></i>
                            </div>
                          </div>
                          <input
                            type="text"
                            class="form-control"
                            :placeholder="userSpecial"
                            v-model="addField"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="field-submit d-flex justify-content-end">
                      <input
                        type="submit"
                        :value="isSubmiting ? 'Processing...' : 'Enter'"
                        :disabled="isSubmiting"
                      />
                    </div>
                  </form>
                </template>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12">
          <infinite-slide-bar duration="24s">
            <div class="block-infinite layout-02 my-5 scroll-items">
              <div class="item">
                <div class="thumb">
                  <img
                    src="@/assets/img/partners/cr.jpg"
                    width="40"
                    alt="Chicken Republic"
                  />

                  <span>Chicken Republic</span>
                </div>
              </div>
              <div class="item">
                <div class="thumb">
                  <img
                    src="@/assets/img/partners/bk.png"
                    width="40"
                    alt="Burger King"
                  />

                  <span>Burger King</span>
                </div>
              </div>
              <div class="item">
                <div class="thumb">
                  <img
                    src="@/assets/img/partners/kili.jpg"
                    width="40"
                    alt="Kilimanjaro"
                  />

                  <span>Kilimanjaro</span>
                </div>
              </div>
              <div class="item">
                <div class="thumb">
                  <img
                    src="@/assets/img/partners/kfc.png"
                    width="40"
                    alt="KFC"
                  />

                  <span>KFC</span>
                </div>
              </div>
              <div class="item">
                <div class="thumb">
                  <img
                    src="@/assets/img/partners/place.png"
                    width="80"
                    alt="The Place"
                  />

                  <span>The Place</span>
                </div>
              </div>
              <div class="item">
                <div class="thumb">
                  <img
                    src="@/assets/img/partners/domi.png"
                    width="60"
                    alt="Dominos"
                  />

                  <span>Dominos</span>
                </div>
              </div>
              <div class="item">
                <div class="thumb">
                  <img
                    src="@/assets/img/partners/tlz.png"
                    width="40"
                    alt="Tantalizer"
                  />

                  <span>Tantalizer</span>
                </div>
              </div>
            </div>
          </infinite-slide-bar>
        </div>
      </div>
    </div>
  </div>
</template>
  
  
  <script>
// main
import { ref, onMounted } from "vue";
import { Timestamp } from "firebase/firestore";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { timestamp } from "@/firebase/config";

//infite scroller
import InfiniteSlideBar from "vue3-infinite-slide-bar";

// others
import getVoucher from "@/composables/firebaseDatabase/getVoucher";
import transactionStartOrder from "@/composables/firebaseDatabase/transactionStartOrder";

export default {
  name: "V",
  props: ["id"],
  components: {
    "infinite-slide-bar": InfiniteSlideBar,
  },
  setup(props) {
    const voucher = ref(null);
    const isCheckingVoucher = ref(false);
    const isSubmiting = ref(false);
    const userName = ref(null);
    const userPhone = ref(null);
    const userSpecial = ref(null);
    const addField = ref(null);
    const error = ref(null);

    // store
    const store = useStore();

    // router
    const router = useRouter();

    onMounted(() => {
      if (props.id) {
        isCheckingVoucher.value = true;

        //[1] clear expired cart
        if (store.state.allCart && store.state.cart) {
          let newAllCart = [];
          const isNow = Timestamp.now().toMillis();
          store.state.allCart.forEach((cart) => {
            if (cart.expiredAt > isNow) {
              newAllCart.push(cart);
            } else {
              if (cart.voucher == store.state.cart.voucher) {
                store.commit("updateCart", null); //clear expired cart
              }
            }
          });
          if (newAllCart.length === 0) {
            store.commit("updateAllCart", null); //null allCart
          } else {
            store.commit("updateAllCart", newAllCart); //update allCart
          }
        }

        //[2] refer cart == voucher
        if (store.state.allCart) {
          for (const cart of store.state.allCart) {
            if (cart.voucher == props.id) {
              store.commit("updateCart", cart); //load latest
              router.push({ name: "MealHome" }); //send to meal order page
              break; //end
            }
          }
        }

        //[3] refer active unexpired
        if (store.state.cart) {
          router.push({ name: "MealHome" }); //send to meal order page
        }

        //[Final] Start Processing
        (async () => {
          const { res, err } = await getVoucher(props.id);

          if (res.value) {
            //check if user get chance
            if (res.value.peopleLeft == 0) {
              error.value = "Sorry, Meal-Token is Used-up!";
            }
            if (Timestamp.now().toMillis() > res.value.expiredAt.toMillis()) {
              error.value = "Sorry, Meal-Token is Expired!";
            }
            if (!error.value) {
              voucher.value = res.value;
              userSpecial.value = res.value.addField;
              userName.value = res.value.receiver;
            }
          } else {
            error.value = err.value;
          }
        })();
      }
    });

    const handleSubmit = async () => {
      // loader
      isSubmiting.value = true;
      error.value = "";

      //error checking
      if (!userName.value || !userPhone.value) {
        error.value = "Enter empty field.";
      } else {
        // data
        const DOCUMENT_PAYLOAD = {
          owner: voucher.value.owner,
          ownerName: voucher.value.sender,
          vid: voucher.value.id,
          orderName: userName.value,
          orderPhone: userPhone.value,
          orderSpecial: addField.value,
          budget: voucher.value.amount,
          createdAt: timestamp,
          status: "p", //pending, requested, completed, successful, saved, deleted
        };

        const { res, err } = await transactionStartOrder(
          voucher.value.owner,
          voucher.value.vid,
          DOCUMENT_PAYLOAD,
          voucher.value.expiredAt
        );

        if (res.value) {
          // initiate
          const CART_UPDATE = {
            name: userName.value,
            phone: userPhone.value,
            budget: voucher.value.amount,
            owner: voucher.value.owner,
            voucher: voucher.value.id,
            order: res.value,
            expiredAt: voucher.value.expiredAt.toMillis(),
            orderConfirmBy: voucher.value.sender,
            isActive: true,
          };

          // update cart
          store.commit("updateCart", CART_UPDATE);

          // add to allCart
          let ALL_CART_UPDATE = [];
          if (store.state.allCart) {
            ALL_CART_UPDATE = store.state.allCart;
          }
          ALL_CART_UPDATE.push({ ...CART_UPDATE });

          // update allCart
          store.commit("updateAllCart", ALL_CART_UPDATE);

          //send to meal order page
          router.push({ name: "MealHome" });
        } else {
          //print error
          error.value = err.value;
        }
      }

      // loader
      isSubmiting.value = false;
    };

    return {
      isCheckingVoucher,
      isSubmiting,
      voucher,
      error,
      userName,
      userPhone,
      userSpecial,
      addField,
      handleSubmit,
    };
  },
};
</script>
  
  <style>
.scroll-items {
  display: flex;
  justify-content: space-around;
}
.text-orange {
  color: orange;
}

.item a:hover {
  color: red;
}

.custom-skyblue-bg {
  background-color: #eef2f7;
}

.custom-yellow-bg {
  background-color: #fcf3cf;
}
.drop {
  border: 2px solid black; /* Set the border style, width, and color */
  padding: 10px; /* Add some padding for spacing */
  color: yellow; /* Set the text color */
  display: inline;
}
</style>
  