import { ref, watchEffect } from 'vue';
import { db } from '@/firebase/config';
import { collection, doc, onSnapshot } from 'firebase/firestore';


const watchVoucherHistory = (ownerId) => {

    // Start   
    const res = ref(null),
          err = ref(null);   

    // Hard-coded variables
    const COLLECTION_NAME = 'owner';   
    const OWNER_DOC = ownerId;
    const SUB_COLLECTION_NAME = 'voucher';


    try {

        // Firebase magic
        const ownerDocRef = doc(db, COLLECTION_NAME, OWNER_DOC);
        const voucherCollectionRef = collection(ownerDocRef, SUB_COLLECTION_NAME);

        const unsub = onSnapshot(voucherCollectionRef, (querySnapshot) => {
            const vouchers = [];

            querySnapshot.forEach((doc) => {
                vouchers.push({ ...doc.data(), id: doc.id });
            });

            res.value = vouchers;

        }, (error) => {
            // Handle the error here
            err.value = error.message;
        });

        watchEffect((onInvalidate) => {
            onInvalidate(() => unsub());
        });

    } catch (error) {
        if (error.code === 'permission-denied') {
            err.value = 'Permission denied';
        } else {
            err.value = 'Error getting Vouchers';
        }
    }

    return { res, err };
};


export default watchVoucherHistory;
