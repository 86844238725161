<template>
  <section class="page-title">
    <div class="container">
      <div class="inner align-center">
        <h1 class="title">Menu</h1>
        <p class="desc" v-if="liveOrder"><span v-if="liveOrder.ownerName">Sponsored by <strong><span class="text-primary">{{ liveOrder.ownerName }}</span></strong></span></p>
      </div>
    </div>
  </section>

  <div class="site-content">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="shop-middle">
            <div
              class="shop-action top-action flex flex-align-c flex-content-sb"
            >
              <div class="results" v-if="menus">Showing {{ menus.length }} of {{ menus.length }} results</div>
              <div class="sort flex flex-align-c flex-content-sb">
                <div class="sort-item sort-category">
                  <select name="category" id="category">
                    <option value="all">All Category</option>
                    <option value="fastfood">Fast Food</option>
                    <option value="swallow">Swallow</option>
                    <option value="pastries">Pastries</option>
                    <option value="drinks">Drinks</option>
                    <option value="pizza">Pizza</option>
                  </select>
                </div>
               
                <div class="sort-item sort-default">
                  <select name="sort" id="sort">
                    <option value="all">Default Sorting</option>
                    <option value="popularity">Popularity</option>
                    <option value="average_rating">Average rating</option>
                    <option value="latest">Latest</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div
                class="col-md-4"
                v-for="menu in menus"
                :key="menu.menu_id"
              >
                <div class="product-card zoom-image">
                  <div class="inner">
                    <div class="product-thumbnail entry-thumbnail">
                      <router-link
                        :to="{
                          name: 'MealOrder',
                          params: { id: menu.menu_id },
                        }"
                        ><img :src="menu.url" :alt="menu.menu"
                      /></router-link>
                    </div>
                    <div class="product-content">
                      <div class="product-cat text-warning">
                        {{ menu.vendor }}
                      </div>
                      <div class="product-title">
                        <h4 class="title">
                          <router-link
                            :to="{
                              name: 'MealOrder',
                              params: { id: menu.menu_id },
                            }"
                            >{{ menu.menu }}</router-link
                          >
                        </h4>
                      </div>
                      <div class="product-price">
                        {{ menu.desc }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="pagination align-center">
            <div class="inner">
              <a href="#" title="Prev" class="prev"
                ><i class="las la-long-arrow-alt-left"></i>Prev</a
              >
              <span>1</span>
              <a href="#" title="2" class="number">2</a>
              <a href="#" title="Next" class="next"
                >Next<i class="las la-long-arrow-alt-right"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
   
<script>
import { ref, onMounted, watchEffect } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import getMenu from "@/composables/getMenu";
import sweetalert2 from 'sweetalert2';

//Watcher
import watchOrder from "@/composables/firebaseDatabase/watchOrder";

export default {
  name: "MealHome",

  setup() {
    const menus = ref(null);
    const error = ref(null);

    // vuex store
    const store = useStore();

    // vue router
    const router = useRouter();

    //scroll to top
    const element = document.getElementById("topmost");
    if (element) {
    element.scrollIntoView({ behavior: "smooth", block: "start" });
    }

    // alert
    onMounted(() => {
      
      if (window.history.state.back.includes("/v/")){
        
      // Show a SweetAlert2 modal
      new sweetalert2({
        title: `Hey, ${store.state.cart.name}`,
        text: 'Feel free, grab any meal of your choice, delivery included!',
      });
      
    }

    });

    // watcher
    const { res: liveOrder, err } = watchOrder(
      store.state.cart.owner,
      store.state.cart.voucher,
      store.state.cart.order
    );

    // watchEffect
    watchEffect(() => {
      if (!store.state.cart) {
        router.push("/");
      }
    });

    watchEffect(() => {
      if (store.state.cart) {
      if (store.state.cart.isActive === false) {
        router.push({ name: 'MealOrder', params: { id: 1 }, });
      }
    }
    });

    onMounted(() => {
      // get menu
      (async () => {
        const { res, error } = await getMenu();

        if (res.value) {
          menus.value = res.value;
        } else {
          error.value = error.value;
        }
      })();
    });

    return { liveOrder, menus, error };
  },
};
</script>
 
<style>
.my-cursor-clicker {
  cursor: pointer;
}
</style>