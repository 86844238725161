<template>
  <div class="site-content pt0 pb0 pt-5">
    <section class="section spdt mt-5">
      <div class="container">
        <div class="heading align-left">
          <div class="heading-sub color-navy">Admin Area</div>
          <div class="heading-sub text-dark">
            Create Meal Token<i class="las la-plus-circle la-2x"></i>
          </div>
        </div>
      </div>
    </section>

    <template v-if="nextStep == 'a'">
      <section class="section">
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <div class="pdr60">
                <div class="heading mb32">
                  <div class="heading-sub layout-02 justify-content-center">
                    <span class="d-none d-lg-block">1. Start</span
                    ><span class="d-lg-none">
                      <i class="las la-coins la-4x"></i
                    ></span>
                  </div>
                  &nbsp;
                  <div class="heading-sub layout-02 bg-light">
                    <span class="d-none d-lg-block">2. Continue</span
                    ><span class="d-lg-none"
                      ><i class="las la-signature la-4x"></i
                    ></span>
                  </div>
                  &nbsp;
                  <div class="heading-sub layout-02 bg-light">
                    <span class="d-none d-lg-block">3. Finish</span
                    ><span class="d-lg-none"
                      ><i class="las la-hourglass-half la-4x"></i
                    ></span>
                  </div>

                  <div class="heading-desc pt-4 text-dark">
                    <u>Set Meal Budget</u>
                  </div>

                  <h2 class="heading-title size-xl pt-2">
                    Start Meal Voucher.
                  </h2>
                </div>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="block-banner background-shade-2 bg-warning">
                <div class="row flex-align-c">
                  <div class="lg-pdl30 lg-pdr30 lg-pdt30 pdl60 pdr60 my-lg-5">
                    <div v-if="error">
                      <p class="alert alert-warning text-danger text-center pb-4">
                        <i class="las la-exclamation la-2x"></i>{{ error }}
                      </p>
                    </div>

                    <form @submit.prevent="handleSubmit">
                      <div class="form-group text-white mb-3">
                        <div class="col-auto">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <div class="input-group-text">
                                <i class="las la-user-plus la-2x"></i>
                              </div>
                            </div>
                            <input
                              type="number"
                              class="form-control"
                              placeholder="Enter No. of people"
                              v-model="people"
                            />
                          </div>
                        </div>
                      </div>

                      <div class="form-group text-white mb-3">
                        <div class="col-auto">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <div class="input-group-text">
                                <i class="las la-coins la-2x"></i>
                              </div>
                            </div>
                            <select class="form-control" v-model="amount">
                              <option value="0">Select cost per person</option>
                              <option value="2800">
                                ₦2,800 (Regular Size) Approx. 10 meal varieties
                                (5 Eatery)
                              </option>
                              <option value="3500">
                                ₦3,500 (Standard Size) Approx. 15 meal varieties
                                (8 Eatery)
                              </option>
                              <option value="5600">
                                ₦5,600 (Large Size) Approx. 25 meal varieties
                                (10 Eatery)
                              </option>
                              <option value="8500">
                                ₦8,500 (Super Size) Approx. 50 meal varieties
                                (15 Eatery)
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div class="field-submit d-flex justify-content-end">
                        <input
                          type="submit"
                          :value="
                            budget > 0
                              ? `Next (₦${budget.toLocaleString()}) &#187;`
                              : 'Next &#187;'
                          "
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p><br /><br /><br /></p>
      </section>
    </template>

    <template v-if="nextStep == 'b'">
      <section class="section">
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <div class="pdr60">
                <div class="heading mb32">
                  <div
                    class="heading-sub layout-02 bg-light my-cursor-clicker"
                    @click="
                      nextStep = 'a';
                      error = null;
                    "
                  >
                    <span class="d-none d-lg-block">1. Start</span
                    ><span class="d-lg-none"
                      ><i class="las la-coins la-4x"></i
                    ></span>
                  </div>
                  &nbsp;
                  <div class="heading-sub layout-02">
                    <span class="d-none d-lg-block">2. Continue</span
                    ><span class="d-lg-none"
                      ><i class="las la-signature la-4x"></i
                    ></span>
                  </div>
                  &nbsp;
                  <div class="heading-sub layout-02 bg-light">
                    <span class="d-none d-lg-block">3. Finish</span
                    ><span class="d-lg-none"
                      ><i class="las la-hourglass-half la-4x"></i
                    ></span>
                  </div>

                  <div class="heading-desc pt-4 text-dark">
                    <u>Sender Details</u>
                  </div>

                  <h2 class="heading-title size-xl pt-2">
                    Budget is set at {{ `₦${budget.toLocaleString()}` }}.
                  </h2>
                </div>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="block-banner background-shade-2 bg-warning">
                <div class="row flex-align-c">
                  <div class="lg-pdl30 lg-pdr30 lg-pdt30 pdl60 pdr60 my-lg-5">
                    <div v-if="error">
                      <p class="alert alert-warning text-danger text-center pb-4">
                        <i class="las la-exclamation la-2x"></i>{{ error }}
                      </p>
                    </div>

                    <form @submit.prevent="handleSubmit">
                      <div class="form-group text-white mb-3">
                        <div class="col-auto">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <div class="input-group-text">
                                <i class="las la-signature la-2x"></i>
                              </div>
                            </div>
                            <input
                              type="text"
                              class="form-control"
                              v-model="ownerName"
                            />
                          </div>
                        </div>
                      </div>

                      <div
                        class="form-group text-white mb-3"
                        v-if="people == 1"
                      >
                        <div class="col-auto">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <div class="input-group-text">
                                <i class="las la-user-circle la-2x"></i>
                              </div>
                            </div>
                            <input
                              type="text"
                              class="form-control"
                              v-model="receiverName"
                              placeholder="Receiver's FirstName"
                            />
                          </div>
                        </div>
                      </div>

                      <div class="form-group text-white mb-3">
                        <div class="col-auto">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <div class="input-group-text">
                                <i class="las la-comment la-2x"></i>
                              </div>
                            </div>
                            <textarea
                              class="form-control"
                              rows="2"
                              v-model="ownerMessage"
                            ></textarea>
                          </div>
                        </div>
                      </div>

                      <div class="field-submit d-flex justify-content-end">
                        <input type="submit" value="Next &#187;" />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p><br /><br /><br /></p>
      </section>
    </template>

    <template v-if="nextStep == 'c'">
      <section class="section">
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <div class="pdr60">
                <div class="heading mb32">
                  <div
                    class="heading-sub layout-02 bg-light my-cursor-clicker"
                    @click="
                      nextStep = 'a';
                      error = null;
                    "
                  >
                    <span class="d-none d-lg-block">1. Start</span
                    ><span class="d-lg-none"
                      ><i class="las la-coins la-4x"></i
                    ></span>
                  </div>
                  &nbsp;
                  <div
                    class="heading-sub layout-02 bg-light my-cursor-clicker"
                    @click="
                      nextStep = 'b';
                      error = null;
                    "
                  >
                    <span class="d-none d-lg-block">2. Continue</span
                    ><span class="d-lg-none"
                      ><i class="las la-signature la-4x"></i
                    ></span>
                  </div>
                  &nbsp;
                  <div class="heading-sub layout-02">
                    <span class="d-none d-lg-block">3. Finish</span
                    ><span class="d-lg-none"
                      ><i class="las la-hourglass-half la-4x"></i
                    ></span>
                  </div>

                  <div class="heading-desc pt-4 text-dark">
                    <u>Voucher Settings</u>
                  </div>

                  <h2 class="heading-title size-xl pt-2">
                    Set your preferences.
                  </h2>
                </div>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="block-banner background-shade-2 bg-warning">
                <div class="row flex-align-c">
                  <div class="lg-pdl30 lg-pdr30 lg-pdt30 pdl60 pdr60 my-lg-5">
                    <div v-if="error">
                      <p class="alert alert-warning text-danger text-center pb-4">
                        <i class="las la-exclamation la-2x"></i>{{ error }}
                      </p>
                    </div>

                    <form @submit.prevent="handleSubmit">
                      <div class="form-group text-white mb-3">
                        <div class="col-auto">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <div class="input-group-text">
                                <i class="las la-hourglass-half la-2x"></i>
                              </div>
                            </div>
                            <select class="form-control" v-model="validFor">
                              <option value="1">
                                Voucher expires after 24hrs
                              </option>
                              <option value="2">
                                Voucher expires after 48hrs
                              </option>
                              <option value="3">
                                Voucher expires after 72hrs
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div class="form-group text-white mb-3">
                        <div class="col-auto">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <div class="input-group-text">
                                <i class="las la-lock-open la-2x"></i>
                              </div>
                            </div>
                            <select
                              class="form-control"
                              v-model="checkAddField"
                            >
                              <option value="1">
                                Use default security fields
                              </option>
                              <option value="2">
                                Add my unique required field
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div
                        class="form-group text-white mb-3"
                        v-if="checkAddField == 2"
                      >
                        <div class="col-auto">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <div class="input-group-text">
                                <i class="las la-plus la-2x"></i>
                              </div>
                            </div>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Group, Department, Rank, etc..."
                              v-model="addField"
                            />
                          </div>
                        </div>
                      </div>

                      <div class="field-submit d-flex justify-content-end">
                        <input
                          type="submit"
                          :value="
                            isSubmiting ? 'Processing...' : 'Create &#10004;'
                          "
                          :disabled="isSubmiting"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p><br /><br /><br /></p>
      </section>
    </template>
  </div>
</template>
     
<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { timestamp, timestampAdd } from "@/firebase/config";

// firebase
import watchOwner from "@/composables/firebaseDatabase/watchOwner";
import transactionCreateVoucher from "@/composables/firebaseDatabase/transactionCreateVoucher";

export default {
  name: "BonoServe",

  setup() {
    const error = ref(null);
    const people = ref(null);
    const amount = ref(0);
    const nextStep = ref("a");
    const isSubmiting = ref(false);
    const ownerName = ref(null);
    const receiverName = ref(null);
    const ownerMessage = ref("Lunch on me. Enjoy!");
    const validFor = ref(1);
    const checkAddField = ref(1);
    const addField = ref(null);
    const orderAuth = ref("y"); // n=no, y=yes sender needs to approve order

    const store = useStore();
    const router = useRouter();

    const { res: liveOwner, err: errOwner } = watchOwner(store.state.user.uid);

    const budget = computed(() => people.value * amount.value);

    //scroll to top
    const element = document.getElementById("topmost");
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }

    const handleSubmit = () => {
      // loader
      error.value = null;

      if (nextStep.value === "c") {
        // loader
        isSubmiting.value = true;

        // voucher validity
        const { newTimestamp } = timestampAdd(validFor.value);

        // process upload
        const DOCUMENT_PAYLOAD = {
          owner: store.state.user.uid,
          sender: ownerName.value,
          receiver: receiverName.value,
          orderAuth: orderAuth.value,
          message: ownerMessage.value,
          addField: addField.value,
          peopleTotal: people.value,
          peopleLeft: people.value,
          orderPending: 0, //p
          orderRequested: 0, //r
          orderCompleted: 0, //c
          orderSuccessful: 0, //s
          orderSaved: 0, //z
          orderDeleted: 0, //d
          orderQueued: 0, //q
          amount: parseFloat(amount.value),
          createdAt: timestamp,
          expiredAt: newTimestamp,
        };

        (async () => {
          const { res, err } = await transactionCreateVoucher(
            store.state.user.uid,
            DOCUMENT_PAYLOAD
          );

          if (res.value) {
            // send to voucher page (payment in production)
            router.push({ name: "BonoVoucher", params: { id: res.value } });
          } else {
            // output error
            error.value = err.value;
          }

          // loader
          isSubmiting.value = false;
        })();
      }

      // nextStep
      if (nextStep.value === "a") {
        if (people.value && amount.value) {
          ownerName.value = liveOwner.value.name; // load account name
          nextStep.value = "b"; // next
        } else {
          error.value = "Complete all fields.";
        }
      } else if (nextStep.value === "b") {
        if (people.value == 1) {
          if (ownerName.value && receiverName.value) {
            orderAuth.value = "n"; // set to n=no sender doesnt need to approve order
            nextStep.value = "c"; // next
          } else {
            error.value = "Complete Sender & Receiver's names.";
          }
        } else {
          if (ownerName.value) {
            orderAuth.value = "y"; // set back to default y=yes sender needs to approve order
            receiverName.value = null; // incase the user bounced around
            nextStep.value = "c"; // next
          } else {
            error.value = "Complete Sender name.";
          }
        }
      }
    };

    return {
      nextStep,
      liveOwner,
      people,
      amount,
      budget,
      isSubmiting,
      ownerName,
      receiverName,
      ownerMessage,
      validFor,
      orderAuth,
      checkAddField,
      addField,
      handleSubmit,
      error,
    };
  },
};
</script>
   
<style scoped>
.my-cursor-clicker {
  cursor: pointer;
}
.custom-yellow-bg {
  background-color: #fcf3cf;
}
.text-white-custom {
  color: white;
}
.box.border-danger {
  border-right-width: 20%;
  border-right-style: solid;
}
.center-image {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>