<template>
  <template v-if="showFooter">
    <!-- BEGIN SITE FOOTER -->
    <footer id="footer" class="footer layout-02">
      <div class="footer-bottom">
        <div class="container mt-5">
          <div class="copyright">
            © 2023 <span class="text-primary">MealBono</span>. All rights
            reserved
          </div>
        </div>
      </div>
    </footer>
    <!-- END SITE FOOTER -->
  </template>
</template>

<script>
import { ref, computed } from "vue";
import { useRoute } from "vue-router";

export default {
  setup() {
    const email = ref(null);
    // route
    const route = useRoute();

    // display
    const showFooter = computed(() => {
      if (route.path.includes("meal/")) {
        return true;
      } else if (route.path.includes("bono/")) {
        return true;
      } else {
        if (route.path.includes("v/")) {
          return true;
        } else {
          return true;
        }
      }
    });

    const handleSubmit = () => {
      email.value = null;
    };

    return {
      email,
      showFooter,
      handleSubmit,
    };
  },
};
</script>

<style>
</style>