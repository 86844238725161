// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getFunctions } from 'firebase/functions';
import { serverTimestamp } from 'firebase/firestore';
import { Timestamp } from 'firebase/firestore';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

//Firebase configuration
const app = initializeApp({
  apiKey: "AIzaSyD26tsoyqOC1ygTHdPzzuB-3Yq6_hXciHs",
  authDomain: "mealbono.firebaseapp.com",
  projectId: "mealbono",
  storageBucket: "mealbono.appspot.com",
  messagingSenderId: "185926368780",
  appId: "1:185926368780:web:743e2fab9ab72bf074b829"
});

// Development AppCheck Variable
// self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;

// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6LdTew8pAAAAAFN13WqAjhL0Uasem0ls_ae5Ey-F'),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true
});

// get firebase services
const auth = getAuth();
const db = getFirestore();
const cloudFunctions = getFunctions();
const timestamp = serverTimestamp();

const timestampAdd = (daysToAdd) => {
const currentTimestamp = Timestamp.now();
const futureTimestamp = currentTimestamp.toDate().getTime() + (daysToAdd * 24 * 60 * 60 * 1000);
const newTimestamp = Timestamp.fromMillis(futureTimestamp);
return { newTimestamp }
};

//export 
export { auth, db, cloudFunctions, timestamp, timestampAdd }