import { ref } from 'vue';
import { db } from '@/firebase/config';
import { doc, setDoc, getDoc } from 'firebase/firestore';


const createOwner = async (documentId, documentData) => {

    // Start
    const ownerRes = ref(null),
          ownerErr = ref(null);

    const COLLECTION_NAME = 'owner';


    try {

        // Firebase magic
        const docRef = doc(db, COLLECTION_NAME, documentId);
        const docSnapshot = await getDoc(docRef);

        if (!docSnapshot.exists()) {
            
            // shoot
            await setDoc(docRef, documentData);

            // load Owner, so we know its done!
            const getDocSnapshot = await getDoc(docRef);
            const result = { ...getDocSnapshot.data(), id: getDocSnapshot.id };
            
            // output
            ownerRes.value = result;

        }

    } catch (error) {
        if (error.code === 'permission-denied') {
            ownerErr.value = 'Permission denied';
        } else {
            ownerErr.value = 'Error getting or setting document, please try again';
        }
    }



    return { ownerRes, ownerErr };

};


export default createOwner;
