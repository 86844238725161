import { ref, watchEffect } from 'vue';
import { db } from '@/firebase/config';
import { doc, onSnapshot } from 'firebase/firestore';

// write error in store
import store from '@/store/index';


const watchOwner = (ownerId) => {

    // Start   
    const res = ref(null),
          err = ref(null);   

    // Hard-coded variables
    const COLLECTION_NAME = 'owner';
    const OWNER_DOC = ownerId;


    try {

        // Firebase magic
        const docRef = doc(db, COLLECTION_NAME, OWNER_DOC);

        const unsub = onSnapshot(docRef, (snapshot) => {
            if (snapshot.exists()) {

                res.value = { ...snapshot.data(), id: snapshot.id };

            } else {

                err.value = 'Owner not found';
                store.commit('newUserState', null);
                
            }
        }, (error) => {
            // Handle the error here
            err.value = error.message;
        });

        watchEffect((onInvalidate) => {
            onInvalidate(() => unsub());
        });

    } catch (error) {
        if (error.code === 'permission-denied') {
            err.value = 'Permission denied';
        } else {
            err.value = 'Error getting Owner';
        }
    }


    return { res, err };


};


export default watchOwner;
