import { ref, watchEffect } from 'vue';
import { db } from '@/firebase/config';
import { doc, onSnapshot } from 'firebase/firestore';


const watchVoucher = (ownerId, voucherId) => {

    // Start   
    const res = ref(null),
          err = ref(null);   

    // Hard-coded variables
    const COLLECTION_NAME = 'owner';   
    const OWNER_DOC = ownerId;
    const SUB_COLLECTION_NAME = 'voucher';
    const VOUCHER_DOC = voucherId;


    try {

        // Firebase magic
        const voucherDocRef = doc(db, COLLECTION_NAME, OWNER_DOC, SUB_COLLECTION_NAME, VOUCHER_DOC);

        const unsub = onSnapshot(voucherDocRef, (snapshot) => {
            if (snapshot.exists()) {

                res.value = { ...snapshot.data(), id: snapshot.id };

            } else {

                err.value = 'Voucher not found';
                
            }

        }, (error) => {
            // Handle the error here
            err.value = error.message;
        });

        watchEffect((onInvalidate) => {
            onInvalidate(() => unsub());
        });

    } catch (error) {
        if (error.code === 'permission-denied') {
            err.value = 'Permission denied';
        } else {
            err.value = 'Error getting Voucher';
        }
    }

    return { res, err };
};


export default watchVoucher;
