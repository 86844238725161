import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import MealHome from '../views/meal/Home.vue'
import MealOrder from '../views/meal/order/Home.vue'
import BonoHome from '../views/bono/Home.vue'
import BonoHistory from '../views/bono/History.vue'
import BonoVoucher from '../views/bono/Voucher.vue'
import BonoAccounts from '../views/bono/Accounts.vue'
import BonoOrders from '../views/bono/Orders.vue'
import BonoServe from '../views/bono/serve/Home.vue'
import BonoLogin from '../views/bono/Login.vue'
import BonoStart from '../views/bono/Start.vue'
import V from '../views/v/Home.vue'


//tester
import Tester from '../views/TestBed.vue'


// authentication routing
import store from '../store/index'

// Routing guide 1
const authRequired = (to, from, next) => {

  const user = store.state.user;

  if (user) {
    // Move on
    next();
  } else {
    // Redirect
    next({ name: 'Home' })
  }

}

// Routing guide 2 (BonoHome)
const authRequiredBono = (to, from, next) => {

  const user = store.state.user;

  if (user) {
    // Move on
    next();
  } else {
    // Redirect
    next({ name: 'BonoLogin' })
  }

}

// Routing guide 3 (MealHome)
const authRequiredMeal = (to, from, next) => {

  const order = store.state.cart;

  if (order) {
    // Move on
    next();
  } else {
    // Redirect
    next({ name: 'Home' })
  }

}


const routes = [
  {
    path: '/tester/',
    name: 'Tester',
    component: Tester
  },
  {
    path: '/:id?',
    name: 'Home',
    component: Home,
    props: true
  },
  {
    path: '/v/:id?',
    name: 'V',
    component: V,
    props: true
  },
  {
    path: '/meal/',
    name: 'MealHome',
    component: MealHome,
    beforeEnter: authRequiredMeal
  },
  {
    path: '/meal/order/:id',
    name: 'MealOrder',
    component: MealOrder,
    props: true,
    beforeEnter: authRequiredMeal
  },
  {
    path: '/bono/',
    name: 'BonoHome',
    component: BonoHome,
    beforeEnter: authRequiredBono
  },
  {
    path: '/bono/history/',
    name: 'BonoHistory',
    component: BonoHistory,
    beforeEnter: authRequiredBono
  },
  {
    path: '/bono/voucher/:id',
    name: 'BonoVoucher',
    component: BonoVoucher,
    props: true,
    beforeEnter: authRequiredBono
  },
  {
    path: '/bono/accounts/',
    name: 'BonoAccounts',
    component: BonoAccounts,
    beforeEnter: authRequiredBono
  },
  {
    path: '/bono/orders/',
    name: 'BonoOrders',
    component: BonoOrders,
    beforeEnter: authRequiredBono
  },
  {
    path: '/bono/serve/',
    name: 'BonoServe',
    component: BonoServe,
    beforeEnter: authRequiredBono
  },
  {
    path: '/bono/login',
    name: 'BonoLogin',
    component: BonoLogin
  },
  {
    path: '/bono/start',
    name: 'BonoStart',
    component: BonoStart
  }
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // TiXo - starts each page from the top
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 };
  },
})


export default router
