<template>
  <section class="page-title">
    <div class="container">
      <div class="inner align-center">
        <h1 class="title">Order</h1>
        <span v-if="liveOrder">
          <p class="pt-3" v-if="liveOrder.status === 'p'">
            <router-link :to="{ name: 'MealHome' }">
              &#171; Go Back
            </router-link>
          </p>
        </span>
        <span v-if="liveOrder">
          <p class="desc" v-if="liveOrder.status !== 'p'">
            <span v-if="liveOrder.ownerName"
              >Sponsored by
              <strong
                ><span class="text-primary">{{
                  liveOrder.ownerName
                }}</span></strong
              ></span
            >
          </p>
        </span>
      </div>
    </div>
  </section>

  <template v-if="menu">
    <div class="site-content" v-if="liveOrder">
      <div class="container">
        <div class="breadcrumbs d-none d-lg-block">
          <div class="inner">
            <ul class="breadcrumb">
              <li v-if="liveOrder.status === 'p'">
                <router-link :to="{ name: 'MealHome' }">Home</router-link>
              </li>
              <li v-if="liveOrder.mealUrl">{{ liveOrder.mealOrder }}</li>
              <li v-else>{{ menu.menu }}</li>
            </ul>
          </div>
        </div>
        <div class="product-detail">
          <div class="row">
            <div class="col-lg-6">
              <div class="view">
                <div class="item zoom">
                  <img
                    :src="liveOrder.mealUrl"
                    class="img-fluid"
                    :alt="liveOrder.mealOrder"
                    v-if="liveOrder.mealUrl"
                  />
                  <img
                    :src="menu.url"
                    class="img-fluid"
                    :alt="menu.menu"
                    v-else
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="product-summary pt-3 pt-lg-0">
                <p>
                  <span v-if="liveOrder.mealUrl">{{ liveOrder.vendor }}</span>
                  <span v-else>{{ menu.vendor }}</span>
                  <i class="las la-check-circle text-success"></i>
                </p>
                <h1>
                  <span v-if="liveOrder.mealUrl">{{
                    liveOrder.mealOrder
                  }}</span>
                  <span v-else>{{ menu.menu }}</span>
                </h1>
                <div class="product-price">
                  <span v-if="liveOrder.mealUrl">{{ liveOrder.mealDesc }}</span>
                  <span v-else>{{ menu.desc }}</span>
                </div>

                <hr class="my-4" />

                <form
                  @submit.prevent="handleSubmit"
                  v-if="liveOrder.status === 'p'"
                >
                  <div v-if="error">
                    <p class="alert alert-warning text-danger text-center pb-3">
                      <i class="las la-exclamation la-2x"></i>
                      {{ error }}
                    </p>
                  </div>

                  <div class="form-group text-white mb-3">
                    <div class="col-auto">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <div class="input-group-text">
                            <i
                              class="las la-user-alt la-2x text-success"
                              :class="{ 'text-danger': !userName }"
                            ></i>
                          </div>
                        </div>
                        <input
                          type="text"
                          class="form-control"
                          v-model="userName"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="form-group text-white mb-3">
                    <div class="col-auto">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <div class="input-group-text">
                            <i
                              class="las la-phone-volume la-2x text-success"
                              :class="{ 'text-danger': !userPhone }"
                            ></i>
                          </div>
                        </div>
                        <input
                          type="text"
                          class="form-control"
                          v-model="userPhone"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="form-group text-white mb-3">
                    <div class="col-auto">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <div class="input-group-text">
                            <i
                              class="las la-map-marker la-2x text-success"
                              :class="{
                                'text-danger': userAddress !== 'complete',
                              }"
                            ></i>
                          </div>
                        </div>

                        <vue-google-autocomplete
                          id="map"
                          type="text"
                          classname="form-control"
                          class="form-control"
                          placeholder="Delivery address"
                          v-model="userAddress"
                          v-on:placechanged="getAddressData"
                        ></vue-google-autocomplete>
                        <div v-if="addressData">
                          <p>{{ addressData.formatted_address }}</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="field-submit my-4">
                    <input
                      type="submit"
                      name="submit"
                      :value="isSubmiting ? 'Processing...' : 'Place Order'"
                      :disabled="isSubmiting"
                    />
                  </div>
                </form>

                <div v-else>
                  <template v-if="liveStatusDisplay">
                    <div class="heading mb32">
                      <div
                        class="heading-sub layout-02"
                        :class="{
                          'bg-success': liveStatusDisplay[2].iconSuccess,
                          'bg-danger': liveStatusDisplay[2].cancel,
                        }"
                      >
                        <span v-if="!liveStatusDisplay[2].cancel">
                          <h5
                            class="heading-title text-white"
                            v-if="liveStatusDisplay[2].iconSuccess"
                          >
                            Order Completed<i class="las la-check-circle"></i>
                          </h5>
                          <h5 class="heading-title" v-else>
                            Order in Progress
                          </h5>
                        </span>
                        <span v-else>
                          <h5 class="heading-title">Order Cancelled</h5>
                        </span>
                      </div>
                    </div>

                    <span v-if="liveOrder.status === 'd'">
                      <p class="text-dark mb-4">
                        {{ liveOrder.ownerName }} has cancelled your order.
                        You're free to contact {{ liveOrder.ownerName }} for
                        futher details.
                      </p>

                      <p class="text-dark mb-4">
                        <span class="btn btn-outline-dark" @click="handleExit"
                          >Exit <i class="las la-window-close"></i
                        ></span>
                        Menu...
                      </p>
                    </span>

                    <div class="block-icon-box layout-16">
                      <div class="item">
                        <div class="icon">
                          <i
                            class="las la-cart-arrow-down la-3x"
                            :class="{
                              'text-success': liveStatusDisplay[0].iconSuccess,
                            }"
                            v-if="liveStatusDisplay[0].icon"
                          ></i>
                          <i
                            class="fas fa-spinner fa-3x fa-spin text-warning"
                            v-if="liveStatusDisplay[0].spinner"
                          ></i>
                          <i
                            class="las la-ban la-3x text-secondary"
                            v-if="liveStatusDisplay[0].cancel"
                          ></i>
                        </div>
                        <div class="content">
                          <h4 class="title">Order Activation</h4>
                          <p class="desc">
                            Order added to
                            <strong>{{ liveOrder.ownerName }}</strong
                            >'s shopping cart
                          </p>
                        </div>
                      </div>
                      <div class="item">
                        <div class="icon">
                          <i
                            class="las la-shopping-bag la-3x"
                            :class="{
                              'text-success': liveStatusDisplay[1].iconSuccess,
                            }"
                            v-if="liveStatusDisplay[1].icon"
                          ></i>
                          <i
                            class="fas fa-spinner fa-3x fa-spin text-warning"
                            v-if="liveStatusDisplay[1].spinner"
                          ></i>
                          <i
                            class="las la-ban la-3x text-secondary"
                            v-if="liveStatusDisplay[1].cancel"
                          ></i>
                        </div>
                        <div class="content">
                          <h4 class="title">Order Confirmation</h4>
                          <p class="desc">
                            Confirmation by
                            <strong>{{ orderConfirmBy }}</strong> {{ sideNote }}
                          </p>
                        </div>
                      </div>
                      <div class="item">
                        <div class="icon">
                          <i
                            class="las la-truck-moving la-3x"
                            :class="{
                              'text-success': liveStatusDisplay[2].iconSuccess,
                            }"
                            v-if="liveStatusDisplay[2].icon"
                          ></i>
                          <i
                            class="fas fa-spinner fa-3x fa-spin text-warning"
                            v-if="liveStatusDisplay[2].spinner"
                          ></i>
                          <i
                            class="las la-ban la-3x text-secondary"
                            v-if="liveStatusDisplay[2].cancel"
                          ></i>
                        </div>
                        <div class="content">
                          <h4 class="title">Order Delivery</h4>
                          <p class="desc">
                            Delivery by
                            <strong
                              ><span v-if="liveOrder.mealUrl">{{
                                liveOrder.vendor
                              }}</span>
                              <span v-else>{{ menu.vendor }}</span></strong
                            >
                          </p>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>

                <!-- <div class="product-tab tab-wrap">
                  <div class="tab-navs">
                    <ul>
                      <li>
                        <a href="#product-description" class="tab-nav is-active"
                          >Description</a
                        >
                      </li>
                      <li>
                        <a href="#additional-information" class="tab-nav"
                          >Additional information</a
                        >
                      </li>
                      <li>
                        <a href="#reviews" class="tab-nav"
                          >Reviews <span>(4)</span></a
                        >
                      </li>
                    </ul>
                  </div>
                  <div id="product-description" class="tab-content is-active">
                    <p>
                      Vix cu primis antiopam. Per unum vocent habemus in. Id
                      delenit consulatu est, ex epicuri salutatus aliquando vix,
                      ad tacimates persequeris mei. Per suas labitur consetetur
                      id. Viris copiosae eleifend mel no, vis cibo summo ea. No
                      mea graeco quaerendum disputationi. Vis an vocent
                      delicatissimi, vis meliore facilis praesent. Eu eum debet
                      interpretaris, eos nominati petentium solet adversarium.
                      Molestie contentiones no usu, atqui possim ipsum.
                    </p>
                  </div>
                  <div id="additional-information" class="tab-content">
                    <ul>
                      <li><span>Color:</span> Blue</li>
                      <li><span>Size:</span> 20cm x 10cm</li>
                      <li><span>Material:</span> Aluminum</li>
                      <li><span>Warranty:</span> Within 6 months</li>
                    </ul>
                  </div>
                  <div id="reviews" class="tab-content tab-review">
                    <h4 class="title">4 reviews for Ucon Acrobatics Hajo</h4>
                    <ul class="list-reviews">
                      <li>
                        <div class="review">
                          <div class="avatar">
                            <img
                              src="assets/images/post-author.webp"
                              alt="Avatar"
                            />
                          </div>
                          <div class="info">
                            <div class="top">
                              <span class="name">Kevin</span>
                              <div class="date">Dec 23, 2022</div>
                              <div class="badge-review">
                                <div class="star">
                                  <span style="width: 100%"></span>
                                </div>
                              </div>
                            </div>
                            <div class="bottom">
                              <p>Good</p>
                            </div>
                          </div>
                        </div>
                        <ul class="children">
                          <li>
                            <div class="review">
                              <div class="avatar">
                                <img
                                  src="assets/images/post-author-01.jpg"
                                  alt="Avatar"
                                />
                              </div>
                              <div class="info">
                                <div class="top">
                                  <span class="name">David</span>
                                  <div class="date">Dec 24, 2022</div>
                                  <div class="badge-review">
                                    <div class="star">
                                      <span style="width: 60%"></span>
                                    </div>
                                  </div>
                                </div>
                                <div class="bottom">
                                  <p>Very Good</p>
                                </div>
                              </div>
                            </div>
                            <ul class="children">
                              <li>
                                <div class="review">
                                  <div class="avatar">
                                    <img
                                      src="assets/images/post-author-02.jpg"
                                      alt="Avatar"
                                    />
                                  </div>
                                  <div class="info">
                                    <div class="top">
                                      <span class="name">Tommy</span>
                                      <div class="date">Dec 25, 2022</div>
                                      <div class="badge-review">
                                        <div class="star">
                                          <span style="width: 80%"></span>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="bottom">
                                      <p>Thanks</p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <div class="review">
                          <div class="avatar">
                            <img
                              src="assets/images/post-author-03.jpg"
                              alt="Avatar"
                            />
                          </div>
                          <div class="info">
                            <div class="top">
                              <span class="name">Jenne</span>
                              <div class="date">Dec 26, 2022</div>
                              <div class="badge-review">
                                <div class="star">
                                  <span style="width: 100%"></span>
                                </div>
                              </div>
                            </div>
                            <div class="bottom">
                              <p>Thanks</p>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <div class="review-form">
                      <h4 class="title">Add your review</h4>
                      <p>
                        Your email address won't be published. Required fields
                        are marked *
                      </p>
                      <form action="#">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="field-input">
                              <label for="name">Name *</label>
                              <input
                                type="text"
                                value=""
                                name="Name"
                                id="name"
                              />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="field-input">
                              <label for="email">Email *</label>
                              <input
                                type="text"
                                value=""
                                name="Email"
                                id="email"
                              />
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="field-input field-rating">
                              <span>Your rating</span>
                              <p class="stars">
                                <a class="star-1" href="#"></a>
                                <a class="star-2" href="#"></a>
                                <a class="star-3" href="#"></a>
                                <a class="star-4" href="#"></a>
                                <a class="star-5" href="#"></a>
                              </p>
                              <input type="hidden" value="0" name="rating" />
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="field-textarea">
                              <label for="content">Your review *</label>
                              <textarea
                                name="content"
                                cols="30"
                                rows="10"
                              ></textarea>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="field-submit">
                              <input
                                type="submit"
                                name="submit"
                                value="Submit"
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div> -->
              
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>
     
<script>
import { ref, computed, onMounted, watchEffect } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { timestamp } from "@/firebase/config";
import sweetalert2 from "sweetalert2";
import VueGoogleAutocomplete from "vue-google-autocomplete";

// Others
import getMenu from "@/composables/getMenu";
import getVoucher from "@/composables/firebaseDatabase/getVoucher";
import transactionSendOrder from "@/composables/firebaseDatabase/transactionSendOrder";

//Watcher
import watchOrder from "@/composables/firebaseDatabase/watchOrder";

export default {
  name: "MealOrder",
  props: ["id"],
  components: { VueGoogleAutocomplete },
  setup(props) {
    const menu = ref(null);
    const isSubmiting = ref(false);
    const orderAuth = ref("y"); // n=no, y=yes sender needs to approve order
    const addressData = ref(null);
    const userAddress = ref(null);
    const userPhone = ref(null);
    const userName = ref(null);
    const error = ref(null);

    // store
    const store = useStore();

    // set confirmer
    const orderConfirmBy = ref(store.state.cart.orderConfirmBy);
    const sideNote = ref(null);

    // router
    const router = useRouter();

    // watcher
    const { res: liveOrder, err } = watchOrder(
      store.state.cart.owner,
      store.state.cart.voucher,
      store.state.cart.order
    );

    //scroll to top
    const element = document.getElementById("topmost");
    if (element) {
    element.scrollIntoView({ behavior: "smooth", block: "start" });
    }

    // watchEffect
    watchEffect(() => {
      if (!store.state.cart) {
        router.push("/");
      }
    });

    onMounted(() => {
      (async () => {
        const { res, error } = await getMenu();

        if (!error.value) {
          let singleMenu = res.value.filter((m) => m.menu_id == props.id);
          menu.value = singleMenu[0];
        } else {
          error.value = error.value;
        }
      })();

      // store values
      userName.value = store.state.cart.name;
      userPhone.value = store.state.cart.phone;
    });

    const liveStatusDisplay = computed(() => {
      // start
      const statusNew = [];

      if (liveOrder.value.status == "p") {
        statusNew.push({
          name: "cart",
          icon: true,
          iconSuccess: false,
          spinner: false,
          cancel: false,
        });
        statusNew.push({
          name: "bag",
          icon: true,
          iconSuccess: false,
          spinner: false,
          cancel: false,
        });
        statusNew.push({
          name: "truck",
          icon: true,
          iconSuccess: false,
          spinner: false,
          cancel: false,
        });
      } else if (
        liveOrder.value.status == "r" ||
        liveOrder.value.status == "q"
      ) {
        statusNew.push({
          name: "cart",
          icon: true,
          iconSuccess: true,
          spinner: false,
          cancel: false,
        });
        statusNew.push({
          name: "bag",
          icon: false,
          iconSuccess: false,
          spinner: true,
          cancel: false,
        });
        statusNew.push({
          name: "truck",
          icon: true,
          iconSuccess: false,
          spinner: false,
          cancel: false,
        });
      } else if (liveOrder.value.status == "c") {
        sideNote.value = null; //removes the time notice
        statusNew.push({
          name: "cart",
          icon: true,
          iconSuccess: true,
          spinner: false,
          cancel: false,
        });
        statusNew.push({
          name: "bag",
          icon: true,
          iconSuccess: true,
          spinner: false,
          cancel: false,
        });
        statusNew.push({
          name: "truck",
          icon: false,
          iconSuccess: false,
          spinner: true,
          cancel: false,
        });
      } else if (liveOrder.value.status == "s") {
        sideNote.value = null; //removes the time notice
        statusNew.push({
          name: "cart",
          icon: true,
          iconSuccess: true,
          spinner: false,
          cancel: false,
        });
        statusNew.push({
          name: "bag",
          icon: true,
          iconSuccess: true,
          spinner: false,
          cancel: false,
        });
        statusNew.push({
          name: "truck",
          icon: true,
          iconSuccess: true,
          spinner: false,
          cancel: false,
        });
      } else if (liveOrder.value.status == "d") {
        sideNote.value = null; //removes the time notice
        statusNew.push({
          name: "cart",
          icon: false,
          iconSuccess: false,
          spinner: false,
          cancel: true,
        });
        statusNew.push({
          name: "bag",
          icon: false,
          iconSuccess: false,
          spinner: false,
          cancel: true,
        });
        statusNew.push({
          name: "truck",
          icon: false,
          iconSuccess: false,
          spinner: false,
          cancel: true,
        });
      }

      return statusNew;
    });

    const handleExit = () => {
      new sweetalert2({
        title: "Are you sure you want to exit current order?",
        text: "Action required for new meal-Token.",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          //clear cart
          if (store.state.cart) {
            store.commit("updateCart", null); //null cart
          }
        }
      });
    };

    const getAddressData = (addressData) => {
      //save for address manipulations
      store.commit("listAddressHistory", addressData);
      // checker
      userAddress.value = "complete";
    };

    const handleSubmit = () => {
      // loader
      isSubmiting.value = true;
      error.value = "";

      //error checking
      if (
        !userName.value ||
        !userPhone.value ||
        userAddress.value !== "complete"
      ) {
        // error
        error.value = "Complete all field(s).";

        // loader
        isSubmiting.value = false;
      } else {
        (async () => {
          const { res: getVoucherRes, err: getVoucherErr } = await getVoucher(
            store.state.cart.voucher
          );

          if (getVoucherRes.value) {
            //check if user get chance
            if (
              store.state.cart.expiredAt >
              getVoucherRes.value.expiredAt.toMillis()
            ) {
              error.value = {
                title: "Sorry, Meal-Token is Expired!",
                text: "This order has been deleted",
              };
            } else {
              orderAuth.value = getVoucherRes.value.orderAuth;
            }
          } else {
            error.value = {
              title: "Something wrong happened!",
              text: "Log back in with same Meal-Token (url)",
            };
          }

          if (error.value) {
            // alert
            new sweetalert2({
              title: error.value.title,
              text: error.value.text,
            });

            // clear cart
            store.commit("updateCart", null); //null cart

            // ends here if error
          }

          // begin submit
          let newOrder = {
            orderName: userName.value,
            orderPhone: userPhone.value,
            orderAddress: store.state.addressHistory,
            mealOrder: menu.value.menu,
            mealDesc: menu.value.desc,
            mealId: menu.value.menu_id,
            mealPrice: menu.value.cost,
            mealUrl: menu.value.url,
            vendor: menu.value.vendor,
            vendorId: menu.value.vendor_id,
            vendorContact: menu.value.phone,
            requestCreatedAt: timestamp,
            status: "r", //pending, requested, queued, completed, successful, saved (z), deleted
          };

          // review newOrder
          if (orderAuth.value == 'n') {
            newOrder.status = 'q';
            newOrder.queueCreatedAt = timestamp;
            newOrder.orderAuth = 'n';
          } else {
            newOrder.status = 'r';
            newOrder.orderAuth = 'y';
          }

          // load results
          const { res, err } = await transactionSendOrder(
            store.state.cart.owner,
            store.state.cart.voucher,
            store.state.cart.order,
            newOrder,
            orderAuth.value
          );

          if (res.value) {
            // who confirmed order
            if (res.value == "n") {
              orderConfirmBy.value = "MealBono";
              sideNote.value = "(may take 5mins..)";
            } else {
              orderConfirmBy.value = liveOrder.value.ownerName;
            }

            //update cart
            store.commit("updateCart", {
              name: userName.value,
              phone: userPhone.value,
              budget: store.state.cart.budget,
              owner: store.state.cart.owner,
              voucher: store.state.cart.voucher,
              order: store.state.cart.order,
              expiredAt: store.state.cart.expiredAt,
              orderConfirmBy: orderConfirmBy.value,
              isActive: false,
            });

            //update allCart
            if (store.state.allCart && store.state.cart) {
              let newAllCart = [];
              store.state.allCart.forEach((cart) => {
                if (cart.voucher === store.state.cart.voucher) {
                  newAllCart.push(store.state.cart);
                } else {
                  newAllCart.push(cart);
                }
              });

              //commit allCart
              store.commit("updateAllCart", newAllCart);
            }
          } else {
            console.log(`Error: ${err.value}`);
            error.value = "Something went wrong!";
          }

          // loader
          isSubmiting.value = false;
        })();
      }
    };

    return {
      liveOrder,
      liveStatusDisplay,
      menu,
      isSubmiting,
      error,
      orderConfirmBy,
      sideNote,
      addressData,
      userName,
      userPhone,
      userAddress,
      handleSubmit,
      handleExit,
      getAddressData,
    };
  },
};
</script>
   
  <style>
.my-cursor-clicker {
  cursor: pointer;
}
</style>