<template>
  <div class="site-content pt0 pb0 pt-5">
    <section class="section spdt mt-5">
      <div class="container">
        <div class="heading align-left">
          <div class="heading-sub color-navy">Admin Area</div>
          <div class="heading-sub">
            Audit Accounts<i class="las la-wallet la-2x"></i>
          </div>
        </div>
      </div>
    </section>

    <div class="container mt-5">
      <div class="heading mb32 d-lg-none" v-if="rCounter">
        <div class="heading-sub layout-02 bg-light">
          <a
            @click="switchDisplay('Wallet')"
            class="my-cursor-clicker"
            title="Wallet"
            >Wallet <span>({{ rCounter.wallet }})</span></a
          >
          <i
            :class="{
              'las la-check-circle text-primary': displayCat == 'Wallet',
            }"
          ></i>
        </div>
        &nbsp;
        <div class="heading-sub layout-02 bg-light">
          <a
            @click="switchDisplay('Spent')"
            class="my-cursor-clicker"
            title="Spent"
            >Spent <span>({{ rCounter.spent }})</span></a
          >
          <i
            :class="{
              'las la-check-circle text-primary': displayCat == 'Spent',
            }"
          ></i>
        </div>
        &nbsp;
        <div class="heading-sub layout-02 bg-light">
          <a @click="switchDisplay('All')" class="my-cursor-clicker" title="All"
            >All <span>({{ rCounter.all }})</span></a
          >
          <i
            :class="{
              'las la-check-circle text-primary': displayCat == 'All',
            }"
          ></i>
        </div>
      </div>

      <div class="row pt-2">
        <div class="col-lg-9">
          <h4 class="heading-title size-2 text-orange pb-2" v-if="liveOwner">
            {{ displayCat }} Receipts
          </h4>

          <section class="section-posttype">
            <div class="posttype">
              <div v-if="Receipts">
                <div class="box bg-info">
                  <div class="content">
                    <h3 class="title" v-if="displayCat == 'All'">
                      Summary of All Transactions for {{ liveOwner.name }}
                    </h3>

                    <h3 class="title" v-if="displayCat == 'Wallet'">
                      Summary of Wallet Deposits for {{ liveOwner.name }}
                    </h3>

                    <h3 class="title" v-if="displayCat == 'Spent'">
                      Summary of Spent Amounts for {{ liveOwner.name }}
                    </h3>

                    <span v-if="show">
                      <hr />
                      <h4
                        class="pt-2"
                        v-if="displayCat == 'All' || displayCat == 'Wallet'"
                      >
                        <i
                          class="las la-money-bill-wave la-2x text-success"
                        ></i>
                        Current Wallet Balance: ({{
                          `₦${liveOwner.wallet
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                        }})
                      </h4>
                      <h4
                        class="pt-2"
                        v-if="displayCat == 'All' || displayCat == 'Wallet'"
                      >
                        <i class="las la-archive la-2x text-orange"></i> Escrow
                        Holding Balance: ({{
                          `₦${liveOwner.escrow
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                        }})
                      </h4>
                      <h4
                        class="pt-2"
                        v-if="displayCat == 'All' || displayCat == 'Spent'"
                      >
                        <i class="las la-minus-circle la-2x text-danger"></i>
                        Total Amount Spent: ({{
                          `₦${liveOwner.spent
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                        }})
                      </h4>
                      <h4 class="pt-2">
                        <i class="las la-coins la-2x text-dark"></i> Total
                        Amount Deposit: ({{
                          `₦${liveOwner.deposit
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                        }})
                      </h4>
                    </span>
                  </div>
                  <div class="button-wrap">
                    <span
                      class="button fullfield my-cursor-clicker"
                      title="Detail"
                      @click="show = !show"
                      >{{ show ? "Close" : "Show"
                      }}<i
                        :class="
                          show
                            ? 'las la-angle-double-up'
                            : 'las la-angle-double-down'
                        "
                      ></i
                    ></span>
                  </div>
                </div>
              </div>

              <div v-for="receipt in Receipts" :key="receipt.id">
                <div class="box">
                  <div class="content">
                    <h3 class="title" v-if="receipt.type == 'w'">
                      Wallet Deposit (Owner) &#187;
                      <span class="text-success">{{ receipt.amount }}</span>
                    </h3>

                    <h3 class="title" v-else>
                      Spent ({{ receipt.orderName }}) &#187;
                      <span class="text-danger">{{ receipt.amount }}</span>
                    </h3>

                    <span v-if="receipt.show">
                      <div class="desc">
                        {{
                          new Date(receipt.createdAt.toMillis()).toLocaleString(
                            undefined,
                            {
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                              weekday: "long",
                              hour: "numeric",
                              minute: "numeric",
                              hour12: true,
                            }
                          )
                        }}
                      </div>

                      <h4 class="title pt-2">
                        {{ receipt.type == "s" ? "Outflow:" : "Inflow:" }}
                        ({{
                          `₦${receipt.amount
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                        }})
                      </h4>
                    </span>
                  </div>
                  <div class="button-wrap">
                    <span
                      class="button fullfield my-cursor-clicker"
                      title="Detail"
                      @click="receipt.show = !receipt.show"
                      >{{ receipt.show ? "Close" : "Show"
                      }}<i
                        :class="
                          receipt.show
                            ? 'las la-angle-double-up'
                            : 'las la-angle-double-down'
                        "
                      ></i
                    ></span>
                  </div>
                </div>
              </div>
            </div>
            <div class="action align-center mt60">
              <p></p>
              <br /><br />
            </div>
          </section>
        </div>
        <div class="col-lg-3">
          <div class="sidebar d-none d-lg-block">
            <aside class="widget widget-category">
              <h3 class="widget-title"><u>Categories</u></h3>
              <div class="widget-content">
                <ul v-if="rCounter">
                  <li>
                    <a
                      @click="switchDisplay('Wallet')"
                      class="my-cursor-clicker"
                      title="Wallet"
                      >Wallet <span>({{ rCounter.wallet }})</span></a
                    >
                    <i
                      :class="{
                        'las la-check-circle text-primary':
                          displayCat == 'Wallet',
                      }"
                    ></i>
                  </li>
                  <li>
                    <a
                      @click="switchDisplay('Spent')"
                      class="my-cursor-clicker"
                      title="Spent"
                      >Spent <span>({{ rCounter.spent }})</span></a
                    >
                    <i
                      :class="{
                        'las la-check-circle text-primary':
                          displayCat == 'Spent',
                      }"
                    ></i>
                  </li>
                  <li>
                    <a
                      @click="switchDisplay('All')"
                      class="my-cursor-clicker"
                      title="All"
                      >All <span>({{ rCounter.all }})</span></a
                    >
                    <i
                      :class="{
                        'las la-check-circle text-primary': displayCat == 'All',
                      }"
                    ></i>
                  </li>
                </ul>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- SITE CONTENT -->
</template>
       
    <script>
import { computed, ref, onMounted } from "vue";
import { useStore } from "vuex";
import { Timestamp } from "firebase/firestore";

// Watcher
import watchOwner from "@/composables/firebaseDatabase/watchOwner";
import getReceipts from "@/composables/firebaseDatabase/getReceipts";

export default {
  name: "BonoAccounts",

  setup() {
    const isShowing = ref(false);
    const show = ref(true);
    const store = useStore();
    const timer = Timestamp.now().toMillis();
    const displayCat = ref("All");
    const receiptsData = ref(null);

    const { res: liveOwner, err: errOwner } = watchOwner(store.state.user.uid);

    //scroll to top
    const element = document.getElementById("topmost");
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }

    onMounted(() => {
      (async () => {
        const { res: receiptsRes, err: receiptsErr } = await getReceipts(
          store.state.user.uid
        );

        if (!receiptsErr.value) {
          receiptsData.value = receiptsRes.value;
        } else {
          error.value = error.value;
        }
      })();
    });

    const switchDisplay = (cat) => {
      displayCat.value = cat;
    };

    const rCounter = computed(() => {
      let w = 0; // wallet deposits
      let s = 0; // spent
      let z = 0; // total

      if (receiptsData.value) {
        receiptsData.value.forEach((receipt) => {
          if (receipt.type == "w") {
            w++;
          } else {
            s++;
          }
          z++;
        });
      }

      // Return an object with the counts
      return { all: z, wallet: w, spent: s };
    });

    const Receipts = computed(() => {
      if (receiptsData.value) {
        //add showDetail
        receiptsData.value.forEach((v) => {
          if (!v.show) {
            v.show = false;
          }
        });

        if (displayCat.value == "All") {
          return receiptsData.value.slice().sort((b, a) => {
            return a.createdAt.toMillis() - b.createdAt.toMillis();
          });
        } else if (displayCat.value == "Wallet") {
          const c = receiptsData.value.filter((receipt) => receipt.type == "w");
          return [...c].sort((b, a) => {
            return a.createdAt.toMillis() - b.createdAt.toMillis();
          });
        } else if (displayCat.value == "Spent") {
          const c = receiptsData.value.filter((receipt) => receipt.type == "s");
          return [...c].sort((b, a) => {
            return a.createdAt.toMillis() - b.createdAt.toMillis();
          });
        }
      }
    });

    return {
      isShowing,
      show,
      timer,
      displayCat,
      liveOwner,
      rCounter,
      switchDisplay,
      Receipts,
    };
  },
};
</script>
     
  <style>
.text-orange {
  color: orange;
}

.item a:hover {
  color: red;
}

.custom-skyblue-bg {
  background-color: #eef2f7;
}

.custom-yellow-bg {
  background-color: #fcf3cf;
}

.custom-light-yellow-bg {
  background-color: rgb(241, 241, 144);
}

.my-cursor-clicker {
  cursor: pointer;
}
</style>