import { ref, watchEffect } from 'vue';
import { db } from '@/firebase/config';
import { doc, onSnapshot } from 'firebase/firestore';

// write error in store
import store from '@/store/index';


const watchOrder = (ownerId, voucherId, orderId) => {

    // Start   
    const res = ref(null),
          err = ref(null);   

    // Hard-coded variables
    const COLLECTION_NAME = 'owner';   
    const OWNER_DOC = ownerId;
    const SUB_COLLECTION_NAME = 'voucher';
    const VOUCHER_DOC = voucherId;
    const SUB_SUB_COLLECTION_NAME = 'orderBox';
    const ORDER_DOC = orderId;


    try {

        // Firebase magic
        const docRef = doc(db, COLLECTION_NAME, OWNER_DOC, SUB_COLLECTION_NAME, VOUCHER_DOC, SUB_SUB_COLLECTION_NAME, ORDER_DOC);

        const unsub = onSnapshot(docRef, (snapshot) => {
            if (snapshot.exists()) {

                res.value = { ...snapshot.data(), id: snapshot.id };
                
                //update cart state
                if (res.value.status !== 'p'){
                store.state.cart.isActive = false;
                store.commit('updateCart', store.state.cart); 
                }

            } else {

                err.value = 'Order not found';
               
                
            }
        }, (error) => {
            // Handle the error here
            err.value = error.message;
        });

        watchEffect((onInvalidate) => {
            onInvalidate(() => unsub());
        });

    } catch (error) {
        if (error.code === 'permission-denied') {
            err.value = 'Permission denied';
        } else {
            err.value = 'Error getting Order';
        }
    }


    return { res, err };


};


export default watchOrder;
