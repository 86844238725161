import { ref } from 'vue';
import { db } from '@/firebase/config';
import { collectionGroup, query, where, getDocs, doc } from 'firebase/firestore';


const getVoucher = async (docId) => {

    // Start
    const res = ref(null),
          err = ref(null);

    // Hard-coded variables
    const SUB_COLLECTION_NAME = 'voucher';

    try {
        // Firebase magic
        const subColRef = collectionGroup(db, SUB_COLLECTION_NAME);
        const q = query(
            subColRef,
            where('vid', '==', docId)
        );
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
            // There should be only one document with the given documentId
            const result = { ...querySnapshot.docs[0].data(), id: querySnapshot.docs[0].id };
            res.value = result;
        } else {
            err.value = 'Error: Meal-Token does not exist!';
        }

    } catch (error) {
        if (error.code === 'permission-denied') {
            err.value = 'Permission denied';
        } else {
            err.value = 'Error getting Token details, please try again';
        }
    }

    return { res, err };

};


export default getVoucher;
