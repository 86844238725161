<template>
  
  <section class="page-title layout-02">
    <div class="container">
      <div class="inner align-center">
        <h1 class="title">Get Started</h1>
        <div class="desc">
          Already a user? <router-link :to="{ name: 'BonoLogin' }" title="Login">Log in account</router-link>
        </div>
      </div>
    </div>
  </section>

  <div class="site-content">
    <div class="container">
      <form @submit.prevent="handleSignUp" class="sign-in" autocomplete="off">

        <div v-if="error">
        <p class="alert alert-warning text-danger text-center pb-4"><i class="las la-exclamation la-2x"></i> {{ error }}</p>
        </div>

        <div class="field-input">
          <label for="email">Your Name*</label>
          <input
            type="text"
            class="form-control"
            v-model="displayName"
            placeholder="ex: Tokunboh Adekoya"
          />
        </div>
        <div class="field-input">
          <label for="email">Email*</label>
          <input
            type="email"
            class="form-control"
            v-model="email"
            placeholder="ex: you@gmail.com"
          />
        </div>
        <div class="field-input field-password">
          <label for="password">Password*</label>
          <input
            :type="passView ? 'text' : 'password'"
            class="form-control"
            v-model="password"
            placeholder="********"
          />
          <i class="lar la-eye view-password" @click="passView = !passView"></i>
        </div>
        
        <div class="field-submit">
          <input type="submit" :value="isSubmiting ? 'Processing...' : 'Enter'" :disabled="isSubmiting" />
        </div>

      </form>
    </div>
  </div>

</template>




<script>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { timestamp } from "@/firebase/config";

import createOwner from "@/composables/firebaseDatabase/createOwner";

export default {
  name: "BonoStart",

  setup() {
    const router = useRouter();
    const isSubmiting = ref(false);
    const email = ref("");
    const password = ref("");
    const passView = ref(false);
    const displayName = ref("");
    const phone = ref("");
    const company = ref("");
    const address = ref("");
    const error = ref("");

    // load store
    const store = useStore();

    //scroll to top
    const element = document.getElementById("topmost");
    if (element) {
    element.scrollIntoView({ behavior: "smooth", block: "start" });
    }

    onMounted(() => {

      if (store.state.user) {
        if (store.state.messenger == 'complete signup') {

          isStarting.value = false;

        }else{
        
          router.push({name: 'BonoLogin'})

        }
    }

    })

    // start signup
    const handleSignUp = async () => {
      
      // loader
      isSubmiting.value = true;
      error.value = '';

      
      if (!email.value || !password.value || !displayName.value) {
        error.value = "Sign-Up Field(s) Empty";
      } else {
      try {
        await store.dispatch("signUp", {
          email: email.value,
          password: password.value,
          displayName: displayName.value,
        });

         // move to Create Voucher...
         router.push({ name: "BonoServe" });

      } catch (err) {
        error.value = err.message;
      }

      
      email.value = "";
      password.value = "";
      displayName.value = "";
     
     }
      
      isSubmiting.value = false;

    };


    return {
      error,
      isSubmiting,
      email,
      password,
      passView,
      displayName,
      phone,
      company,
      address,
      handleSignUp,
    };
  },
};
</script>

<style>
</style>