// Transaction Start Order
import { doc, runTransaction, increment, collection, setDoc } from 'firebase/firestore';
import { Timestamp } from "firebase/firestore";
import { db } from '@/firebase/config';
import { ref } from 'vue';

const transactionSendOrder = async (ownerId, voucherId, orderId, document) => {
    // Start
    const res = ref(null);
    const err = ref(null);

    // Hard-coded variables
    const COLLECTION_NAME = 'owner';
    const SUB_COLLECTION_NAME = 'voucher';
    const VOUCHER_DOC = voucherId;
    const SUB_SUB_COLLECTION_NAME = 'orderBox';
    const INCREASE_PEOPLE_COUNT = 1;
    const DECREASE_PEOPLE_COUNT = -1;
    const OWNER_DOC = ownerId;
    const ORDER_DOC = orderId;
    const DOCUMENT_DATA = document;

    // Firebase Variables
    const voucherRef = doc(db, COLLECTION_NAME, OWNER_DOC, SUB_COLLECTION_NAME, VOUCHER_DOC);
    const orderRef = doc(db, COLLECTION_NAME, OWNER_DOC, SUB_COLLECTION_NAME, VOUCHER_DOC, SUB_SUB_COLLECTION_NAME, ORDER_DOC);

    try {


            await runTransaction(db, async (transaction) => {

                    // Update voucher record
                    transaction.update(voucherRef, { orderDeleted: increment(INCREASE_PEOPLE_COUNT), orderRequested: increment(DECREASE_PEOPLE_COUNT) });

                    // Update voucher record
                    transaction.update(orderRef, DOCUMENT_DATA);

                    // Output response
                    res.value = orderId;

            });


    } catch (error) {

        if (error.code === "permission-denied") {
            // Permission error
            err.value = "Permission denied";
        } else {
            // Handle other non-permission errors here
            err.value = "Error sending Order, please try again";
        }

    }


    return { res, err }
};


export default transactionSendOrder;