<template>
  <!-- BEGIN SITE HEADER -->
  <header
    id="header"
    class="site-header is-transparent"
    v-if="showNav == 'main'"
  >
    <div class="container-fluid">
      <div class="row">
        <!-- removed flex ( flex-align-c inner )-->
        <div class="col-lg-3 col-6">
          <div class="header-left flex flex-align-c">
            <div class="logo">
              <router-link to="/">
                <img
                  src="@/assets/img/mealbono.png"
                  alt="MealBono"
                  class="img-fluid"
                />
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-0 text-center">
          <div class="header-center">
            <div class="main-menu">
              <div class="menu-action">
                <span class="item menu-back"
                  ><i class="las la-arrow-left"></i
                ></span>
                <span class="item menu-close"
                  ><i class="las la-times"></i
                ></span>
              </div>
              <ul>
                <li class="is-mega-menu">
                  <a href="#" @click.prevent="scrollSmooth('howItWorks')"
                    >How it works</a
                  >
                </li>
                <li class="is-mega-menu">
                  <a href="#" @click.prevent="scrollSmooth('partners')"
                    >Partners</a
                  >
                </li>
                <li class="is-mega-menu">
                  <a href="#" @click.prevent="scrollSmooth('faqs')">FAQs</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-6">
          <div class="header-right flex flex-align-c flex-content-e">
            <template v-if="userName">
              <div class="customer">
                <router-link :to="{ name: 'BonoHome' }">
                  <span class="text-primary"> {{ userName }}</span>
                </router-link>
              </div>
              <div class="buttons">
                <router-link
                  class="button fullfield-dark d-none d-lg-block"
                  :to="{ name: 'BonoLogin' }"
                  ><span>Logout</span></router-link
                >
                <span
                  class="button fullfield-dark my-cursor-clicker d-lg-none"
                  @click="isOpen = true"
                  ><i class="las la-bars la-2x" title="Menu"></i
                ></span>
              </div>
            </template>
            <template v-else>
              <div class="buttons">
                <router-link
                  class="button fullfield d-none d-lg-block"
                  :to="{ name: 'BonoLogin' }"
                  ><span>Login</span></router-link
                >
                <span
                  class="button fullfield-dark my-cursor-clicker d-lg-none"
                  @click="isOpen = true"
                  ><i class="las la-bars la-2x" title="Menu"></i
                ></span>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>

    <!-- MOBILE -->
    <Slide
      :isOpen="isOpen"
      @closeMenu="isOpen = false"
      :closeOnNavigation="true"
      :burgerIcon="false"
      :width="viewportWidth"
      noOverlay
      right
    >

    <router-link :to="{ name: 'BonoHome' }" v-if="userName">
        <span class="text-secondary h3">{{ userName }}</span>
      </router-link>

      <router-link to="/">
        <span class="text-info h1"
          ><img
            src="@/assets/img/mealbono-menu.png"
            alt="MealBono"
            class="img-fluid"
        /></span>
      </router-link>

      <a href="#" @click.prevent="scrollSmooth('howItWorks')"
        ><span>How it works</span></a
      >

      <hr width="75%" />

      <a href="#" @click.prevent="scrollSmooth('partners')"
        ><span>Partners</span></a
      >

      <hr width="75%" />

      <a href="#" @click.prevent="scrollSmooth('faqs')"><span>FAQs</span></a>

      <hr width="75%" v-if="userName" />
      <router-link v-if="userName" :to="{ name: 'BonoLogin' }"
        ><span class="button fullfield-dark">Logout</span></router-link
      >
      <hr width="75%" v-if="!userName" />
      <router-link v-if="!userName" :to="{ name: 'BonoLogin' }"
        ><span class="button fullfield">Login</span></router-link
      >
      <p><br /><br /></p>
    </Slide>
  </header>
  <!-- END SITE HEADER -->
</template>

<script>
import { computed, ref, watchEffect, onMounted, onBeforeUnmount } from "vue";
import { Slide } from "vue3-burger-menu";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

export default {
  components: { Slide },
  props: ["userName", "userEmail"],
  setup(props) {
    const userEmail = ref("");
    const userName = ref("");
    const isOpen = ref(false);

    watchEffect(() => {
      userName.value = props.userName;
      userEmail.value = props.userEmail;
    });

    // route
    const route = useRoute();

    // router
    const router = useRouter();

    // store
    const store = useStore();

    // Create a ref to store the viewport width
    const viewportWidth = ref(`${window.innerWidth}`);

    // Function to update the viewport width
    const updateViewportWidth = () => {
      viewportWidth.value = `${window.innerWidth}`;
    };

    // Add event listener on component mount
    onMounted(() => {
      window.addEventListener("resize", updateViewportWidth);
    });

    // Remove event listener on component unmount
    onBeforeUnmount(() => {
      window.removeEventListener("resize", updateViewportWidth);
    });

    const showNav = computed(() => {
      if (route.path.includes("v/")) {
        return "voucher";
      } else {
        return "main";
      }
    });

    const scrollSmooth = (id) => {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    };

    return {
      viewportWidth,
      showNav,
      userEmail,
      userName,
      isOpen,
      scrollSmooth,
    };
  },
};
</script>

<style>
.my-cursor-clicker {
  cursor: pointer;
}
</style>