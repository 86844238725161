// broadcastChannel.js
export function sendMessageAuth(message) {
    const channel1 = new BroadcastChannel('myAppChannel1');
    channel1.postMessage(message);
    channel1.close();
  }
  
  export function receiveMessageAuth(callback) {
    const channel1 = new BroadcastChannel('myAppChannel1');  
    channel1.onmessage = (event) => {
      callback(event.data);
    };
  }

  export function sendMessageCart(message) {
    const channel2 = new BroadcastChannel('myAppChannel2');
    channel2.postMessage(message);
    channel2.close();
  }
  
  export function receiveMessageCart(callback) {
    const channel2 = new BroadcastChannel('myAppChannel2');  
    channel2.onmessage = (event) => {
      callback(event.data);
    };
  }