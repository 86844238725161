import { ref } from 'vue';
import { db } from '@/firebase/config';
import { doc, getDoc } from 'firebase/firestore';


const getOwner = async (col, documentId) => {

    // Start
    const res = ref(null),
          err = ref(null);

    try {

        // Firebase magic
        const docRef = doc(db, col, documentId);
        const docSnapshot = await getDoc(docRef);

        if (docSnapshot.exists()) {
            const result = { ...docSnapshot.data(), id: docSnapshot.id };
            res.value = result;
        } else {
            err.value = 'Owner not found';
        }

    } catch (error) {
            
        if (error.code === 'permission-denied') {
            err.value = 'Permission denied';
        } else {
            err.value = 'Error Initializing User - Please try again';
        }

    }


    return { res, err };

};


export default getOwner;
