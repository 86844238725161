<template>
  <div class="site-content pt0 pb0 pt-5">
    <section class="section spdt mt-5">
      <div class="container">
        <div class="heading align-left">
          <div class="heading-sub color-navy">Admin Area</div>
        </div>
        <div class="block-number">
          <div class="row">
            <div class="col-md-6">
              <router-link :to="{name: 'BonoAccounts'}">
                <div class="item">
                  <h4 class="title">Balance (<b>₦</b>)</h4>
                  <div class="number">
                    <span v-if="liveOwner">{{ `${liveOwner.wallet.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` }}.</span>
                    <span class="last">00</span>
                  </div>
                </div>
              </router-link>
            </div>

            <div class="col-md-6">
              <router-link :to="{name: 'BonoAccounts'}">
                <div class="item">
                  <h4 class="title">Escrow (<b>₦</b>)</h4>
                  <div class="number">
                    <span v-if="liveOwner">{{ `${liveOwner.escrow.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` }}.</span>
                    <span class="last">00</span>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>

    
    <section class="section pt120 spdb">
      <div class="container">
        <div class="row flex-align-c custom-yellow-bg">
          <div class="col-lg-6">
            <div class="images layout-02 lg-mb60">
              <div class="inner background-container py-5">
                <img
                  class="img01"
                  src="@/assets/images/cw-07.png"
                  alt="Image"
                />
                <img
                  class="img07 lg-hidden"
                  src="@/assets/images/asc8.png"
                  alt="Image"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-6 py-5">
            <div class="heading mb24">
              <div class="heading-sub color-navy"><i class="las la-tags la-2x"></i>Create</div>
              <h2 class="heading-title size-1">Meal-Token</h2>
              <div class="heading-desc">
                MealBono is the easiest way to send meals to anyone, anywhere. Start by generating a meal-Token within your budget (from N2,700 only)
              </div>
            </div>
            <div class="block-list layout-01 block-list-flex">
              <ul>
                <li>
                  <i class="las la-check"></i>
                  <span>For an Individual.</span>
                </li>
                <li>
                  <i class="las la-check"></i>
                  <span>For a Group.</span>
                </li>
              </ul>
            </div>
            <div class="button-wrap mt32">
              <router-link
                :to="{name: 'BonoServe'}"
                class="button fullfield"
                title="Create Token"
                >Get Started &#187;</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </section>


    <section class="section spdtb">
      <div class="container">
        <div class="block-icon-box layout-02">
          <div class="row">
            <div class="col-lg-3 col-md-6">
              <router-link :to="{name: 'BonoServe'}">
                <div class="icon-box align-center p30">
                  <div class="inner">
                    <div class="icon">
                      <i class="las la-plus-circle la-4x text-success"></i>
                    </div>
                    <div class="content">
                      <h3 class="title">Create New eVoucher</h3>
                      <div class="desc">
                      Generate a meal-Token within your budget.
                      </div>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
            <div class="col-lg-3 col-md-6">
              <router-link :to="{name: 'BonoHistory'}">
                <div class="icon-box align-center p30">
                  <div class="inner">
                    <div class="icon">
                      <i class="las la-tools la-4x text-primary"></i>
                    </div>
                    <div class="content">
                      <h3 class="title">Manage Vouchers</h3>
                      <div class="desc">
                        View every meal-Tokens created.
                      </div>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
            <div class="col-lg-3 col-md-6">
              <router-link :to="{name: 'BonoOrders'}">
                <div class="icon-box align-center p30">
                  <div class="inner">
                    <div class="icon">
                      <i class="las la-shopping-bag la-4x text-danger"></i>
                    </div>
                    <div class="content">
                      <h3 class="title">Review Order Cart</h3>
                      <div class="desc">
                        View all active Orders pending completion.
                      </div>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
            <div class="col-lg-3 col-md-6">
              <router-link :to="{name: 'BonoAccounts'}">
                <div class="icon-box align-center p30">
                  <div class="inner">
                    <div class="icon">
                      <i class="las la-wallet la-4x text-warning"></i>
                    </div>
                    <div class="content">
                      <h3 class="title">Check Account & Balances</h3>
                      <div class="desc">
                        View all transactions on this account.
                      </div>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <!-- SITE CONTENT -->
</template>

   
<script>
import { computed, ref, watchEffect } from "vue";
import { useStore } from "vuex";

// Watcher
import watchOwner from "@/composables/firebaseDatabase/watchOwner";
import watchOrderBox from "@/composables/firebaseDatabase/watchOrderBox";
import watchOwnerActiveVouchers from "@/composables/firebaseDatabase/watchOwnerActiveVouchers";

export default {
  name: "BonoHome",

  setup() {
    const menus = ref(null);
    const store = useStore();

    const { res: liveOwner, err: errOwner } = watchOwner(store.state.user.uid);
    const { res: liveOrderBox, err: errOrder } = watchOrderBox(
      store.state.user.uid
    );
    const { res: liveOwnerActiveVouchers, err: errVoucher } =
      watchOwnerActiveVouchers(store.state.user.uid);

    //scroll to top
    const element = document.getElementById("topmost");
    if (element) {
    element.scrollIntoView({ behavior: "smooth", block: "start" });
    }

    const sortedOrders = computed(() => {
      if (liveOrderBox.value) {
        return [...liveOrderBox.value].sort((b, a) => {
          return a.createdAt.toMillis() - b.createdAt.toMillis();
        });
      }
    });

    const sortedVouchers = computed(() => {
      if (liveOwnerActiveVouchers.value) {
        return [...liveOwnerActiveVouchers.value].sort((b, a) => {
          return a.createdAt.toMillis() - b.createdAt.toMillis();
        });
      }
    });

    return { menus, liveOwner, sortedOrders, sortedVouchers };
  },
};
</script>
 
<style>
.text-orange {
  color: orange;
}

.item a:hover {
  color: red;
}

.custom-skyblue-bg {
  background-color: #eef2f7;
}

.custom-yellow-bg {
  background-color: #fcf3cf;
}
.background-container {
    background-image: url('@/assets/images/asc3.png');
    background-size: cover; /* Adjust to your preferred size */
    background-repeat: no-repeat;
    height: 55vh; /* Adjust to your preferred height */
}
</style>