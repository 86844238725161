import { createStore } from 'vuex'
import { auth } from '@/firebase/config.js'
import {
  createUserWithEmailAndPassword,
  updateProfile,
  signInWithEmailAndPassword,
  signInWithCustomToken,
  signOut,
  onAuthStateChanged
} from 'firebase/auth'

import axios from 'axios';
import VuexPersist from 'vuex-persist'
import { sendMessageAuth, receiveMessageAuth, sendMessageCart, receiveMessageCart } from '@/broadcast/index';


const vuexPersist = new VuexPersist({
  key: 'mealBonoData',
  storage: window.localStorage,
  reducer: (state) => ({ messenger: state.messenger, allCart: state.allCart, cart: state.cart, addressHistory: state.addressHistory, user: state.user })
})

const store = createStore({
  state: {
    user: null,
    authIsReady: false,
    messenger: null,
    allCart: null,
    cart: null,
    addressHistory: null,
  },
  plugins: [vuexPersist.plugin],
  mutations: {
    newUserState(state, payload) {
      state.user = payload;
    },
    changeAuthIsReady(state, payload) {
      state.authIsReady = payload;
    },
    sendMessenger(state, payload) {
      state.messenger = payload;
    },
    updateAllCart(state, payload) {
      state.allCart = payload; //array
    },
    updateCart(state, payload) {
      if (payload === null) {
        sendMessageCart(payload) // Send message before update
      }
      state.cart = payload;
    },
    updateCartNull(state, payload) {
      state.cart = payload; 
    },
    listAddressHistory(state, payload) {
      state.addressHistory = payload;
    },
  },

  actions: {
    async signUp(context, { email, password, displayName }) {
      try {
        const response = await axios.post('https://signup-py4nupxwjq-uc.a.run.app', {
          email,
          password,
          displayName,
        }, {
          headers: {
            'Content-Type': 'application/json', // Make sure this is allowed by the server
          },
        });

        if (response.data.loginToken) {
          const user = await signInWithCustomToken(auth, response.data.loginToken); // Sign in with the custom token
          context.commit('newUserState', user.user); // commit
        } else {
          throw new Error(response.data.error); // error array from server
        }

      } catch (error) {
        if (error.message.includes("Network Error")){       
          throw new Error("Check Internet Connection");
        }else{
         throw new Error(error.message);
        } 
      }
    },

    async signIn(context, { email, password }) {
      try {
        const res = await signInWithEmailAndPassword(auth, email, password)
        if (res) {
          context.commit('newUserState', res.user) // commit
        } else {
          throw new Error('Unable to Sign In')
        }
      } catch (error) {
        if (error.message.includes("auth/network-request-failed")){       
          throw new Error("Check Internet Connection");

        }else if (error.message.includes("auth/user-not-found")){
          throw new Error("Email/Password Mismatch");

        }else if (error.message.includes("auth/wrong-password")){
          throw new Error("Wrong Password Entered");

        }else{
          throw new Error("Something Wrong Occurred");
        }  
       
      }
    },

    async logOut(context) {
      try {
        await signOut(auth)

        context.commit('newUserState', null)
        context.commit('sendMessenger', null)

        sendMessageAuth(null) // Send the updated value to other browser panels

      } catch (error) {
        throw new Error("Something Wrong Occurred");
      }

    }

  }
})


const unsub = onAuthStateChanged(auth, (user) => {
  store.commit('changeAuthIsReady', true)
  store.commit('newUserState', user)
  unsub()
})


receiveMessageAuth((newAuthload) => {
  store.commit('newUserState', newAuthload); // Update the value in the store when receiving a message // receiveMessage function is called 1st
});


receiveMessageCart((newCartload) => {
  store.commit('updateCartNull', newCartload); // Update the value in the store when receiving a message // receiveMessage function is called 1st
});


export default store
